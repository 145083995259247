import { RAW_FORM_TYPE_READONLY_INPUT } from '@core/models/raw-form.constants';
import { IRawForm, IRawFormInput } from '@core/models/raw-form.types';
import {
  getButton,
  getCheckbox,
  getDictSelect,
  getDummy,
  getInput,
  getLabel,
  getRadio, getReadOnly, getTable,
  getTextArea,
} from '@core/utilities/raw-form.utils';
import {
  CAR_ACCESSIBILITY_TO_MARINE_VESSELS,
  CAR_AGREEMENT_IDENTIFIER,
  CAR_ALPHANUMERIC_CI_FLD,
  CAR_ANNUAL_CO2_GASEOUS_CREDITS,
  CAR_ANNUAL_CO2_LIQUID_CREDITS,
  CAR_ANNUAL_COMPLIANCE_CREDIT_CREATED_VALUE,
  CAR_ANNUAL_ENTER_CREDIT_INFO_LABEL,
  CAR_ANNUAL_LIQUID_COMPLIANCE_CREDITS_GAS_LABEL,
  CAR_CHARGING_STATION_FLD,
  CAR_CHARGING_STATION_ID_FLD,
  CAR_CHARGING_STATION_NAME_FLD,
  CAR_CI_ID,
  CAR_CI_ID_FLD,
  CAR_CI_LIQUID_CI_FUEL_FLD,
  CAR_CIVIC_ADDRESS,
  CAR_CLEAR_BUTTON,
  CAR_COMPLIANCE_CREDIT_CREATED_VALUE,
  CAR_CONCLUSION_COMMENT,
  CAR_CONTACT_PERSON_NAME_FLD,
  CAR_CREDITS_TYPE_FLD,
  CAR_CREDITS_TYPE_ID,
  CAR_DEFAULT_ENERGY_EFFICIENCY_RATIO,
  CAR_DEFAULT_ENERGY_EFFICIENCY_RATIO_FLD,
  CAR_EDIT_ADDRESS,
  CAR_ELECTRICAL_ENERGY_OUTPUT,
  CAR_ENERGY_EFFICIENCY_RATIO,
  CAR_EQUIPMENT_SERIAL_NO_FLD,
  CAR_FACILITY_CIVIC_ADDRESS,
  CAR_FACILITY_FLD,
  CAR_FACILITY_ID_FLD,
  CAR_FEEDSTOCK_NAME_FLD,
  CAR_FEEDSTOCK_REGION_FLD,
  CAR_FOREIGN_SUPPLIER_NAME,
  CAR_FUEL_FLD,
  CAR_FUEL_ID_FLD,
  CAR_FUEL_REPLACEMENT_FLD,
  CAR_FUEL_REPLACEMENT_TYPE,
  CAR_FUELING_STATION_NAME,
  CAR_FUELING_STATION_NAME_LABEL,
  CAR_FUELING_STATION_OWNER_NAME,
  CAR_FUELING_STATION_OWNER_NAME_LABEL,
  CAR_FUELING_STATION_REGISTERED,
  CAR_LIQUID_COMPLIANCE_CREDITS_FLD,
  CAR_LIQUID_COMPLIANCE_CREDITS_GAS_LABEL,
  CAR_LO_CI_FUEL_ENERGY_DENSITY_FLD,
  CAR_LO_CI_FUEL_QUANTITY_FLD,
  CAR_MANUAL_ENERGY_DENSITY,
  CAR_OPINION_TYPE,
  CAR_ORGNIZATION_ID,
  CAR_ORGNIZATION_ID_PLACEHOLDER,
  CAR_OVERRIDE_CREDITS_NUMBER_FLD,
  CAR_PHYSICAL_ADDRESS_OF_LOCATION,
  CAR_POSTAL_ADDRESS,
  CAR_PREFIX,
  CAR_PROJECT_ID_FLD,
  CAR_QUANTITY_FUEL_SUPPLIED_TO_VEHICLE_FLD,
  CAR_QUANTITY_OF_HYDROGEN,
  CAR_QUANTITY_RENEWABLE_VEHICLE_FUEL_FLD,
  CAR_QUANTITY_VEHICLE_FUEL_FLD,
  CAR_QUARTER_FLD,
  CAR_REASON_FOR_OVERRIDING_FLD,
  CAR_REFERENCE_CI_OF_ELECTRICITY,
  CAR_SEARCH_BUTTON,
  CAR_SECTION_FLD,
  CAR_SECTION_VALIDATION_EQUATION,
  CAR_STATION_ID,
  CAR_STATION_ID_PLACEHOLDER,
  CAR_STEP_PREFIX,
  CAR_TOTAL_GASEOUS_CREDITS_CREATED,
  CAR_TOTAL_LIQUID_DIESEL_CREDITS_CREATED,
  CAR_TOTAL_LIQUID_GASOLINE_CREDITS_CREATED,
  CAR_TOTAL_LIQUID_OTHER_CREDITS_CREATED,
  CAR_TOTAL_TOTAL_LIQUID_CREDITS_CREATED,
  CAR_TRANSFEREE,
  CAR_UPDATED_COMPLIANCE_CREDIT_VALUE,
  CAR_VIEW_ADDRESS,
  CAR_VIEW_CONTACT_PERSON_INFO,
  CAR_VIEW_PROJECT_INFO,
  CAR_VOLUME_RENEWABLE_VEHICLE_FUEL,
  CARApplyPrefix,
  CAR_COMPLIANCE_CREDITS_CREATED_TOOLTIP,
  CAR_FUEL_TYPE_TOOLTIP,
  CAR_FACILITY_NAME_TOOLTIP,
  CAR_ALPHANUMERIC_ID_TOOLTIP,
  CAR_CARBON_INTENSITY_FUEL_TOOLTIP,
  CAR_CREDITS_TYPES_TOOLTIP,
  CAR_FUEL_REPLACEMENT_TYPE_TOOLTIP,
  CAR_QUANTITY_DIFFERENCE_PARA_TOOLTIP,
  CAR_QUANTITY_OF_LOW_CI_FUEL_TOOLTIP,
  CAR_ENERGY_DENSITY_TOOLTIP,
  CAR_FEED_STOCK_TYPE_TOOLTIP,
  CAR_READONLY_FEED_STOCK_TYPE_TOOLTIP,
  CAR_LIQCOMP_TOTAL_CREDITS_TOOLTIP,
  CAR_LO_CI_FUEL_QUANTITY_DIFF_FLD,
  CAR_CARBON_INTENSITY_OF_FUEL,
  CAR_QUANTITY_OF_FUEL,
  CAR_NUMBER_OF_CREDITS_TO_BE_TRANSFERED,
  CAR_NUMBER_OF_CREDITS_OF_PARTICIPANT,
  CAR_STEP_5_TABLE_TITLE,
  CAR_STEP_5_CREDITS_HIDE_EXPRESSION,
  CAR_CREDITS_BLOCKS_TO_BE_CANCELLED_TABLE_ID,
  CAR_COMPLIANCE_CREDITS_TO_BE_DEPOSITED_FLD,
  CAR_COMPLIANCE_CREDITS_TO_BE_CANCELLED_FLD,
  CAR_DESCRIPTION_OF_CHANGE_OR_ERROR,
  CAR_FUEL_QUANTITY_FLD,
  CAR_SAME_QUANTITY_AS_IN_CCR,
  CAR_OVERRIDE_THE_DIFF_OF_QUANTITY_CHECKBOX_FLD,
  CAR_OVERRIDE_THE_DIFF_OF_QUANTITY_FLD,
  CAR_OVERRIDE_THE_DIFF_OF_QUANTITY_REASON_FLD,
  CAR_BLANK_LABEL,
  CAR_REFERENCE_CI_OF_ELECTRICITY_CODE_FLD,
  CAR_COMPLIANCE_CREDITS_DEPOSITED_FINAL,
  CAT_COMPLIANCE_CREDITS_CANCELLED_FINAL,
  CREDITS_CREATED_FOR_PARTICIPANT_STEP7_TOOLTIP,
  ELECTRICITY_ENERGY_EQUIP_TOOLTIP,
  CAR_BIOGAS_ENERGY_OUTPUT_DIFF,
  CAR_BIOGAS_ENERGY_PRODUCED_DIFF,
  CAR_SAME_ENERGY_OUTPUT_AS_IN_CCR,
  CAR_SAME_ENERGY_PRODUCED_AS_IN_CCR,
  CAR_FACILITY_DIFFERENT_FROM_BIOGAS,
  CAR_ELECTRICITY_FACILITY_NAME,
  CAR_UNDER_SECTION,
  CAR_UNDER_SECTION_DIFF_PERSON_ELGIBILITY,
  CAR_INSTRUMENT_CI_ID,
  CAR_CI_INSTRUMENT_ID,
  CAR_CI_ADJUSTED_SECTION_89,
  CAR_ENERGY_DENSITY_INPUT_LABEL,
} from '@module/credit-adjustment-report/const/credit-adjustment-report.const';
import { SOURCE_DATA_TYPE } from '@shared/components/stepper/stepper.const';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ITableViewConfig } from '@shared/models/table-view.model';
import { Subject } from 'rxjs';
import { IApp } from '@core/models/app.interfaces';
import { TemplateRef } from '@angular/core';
import { CCRApplyPrefix } from '@module/credit-creation-reports/routines/create-credis-reports.constats';
import { randomStr } from '@core/utilities/utilities.constants';

export const getCreditCreationReportFacility = (source: BehaviorSubject<any>, onChange?: (event) => void, label = CAR_FACILITY_FLD, ignoreTranslateOptions = true, translatePrefix = CAR_PREFIX + CAR_STEP_PREFIX, bindValue = 'id', idKey = CAR_FACILITY_ID_FLD,tooltip?:string): IRawForm => {
  return getDictSelect({
    id: idKey,
    className: 'col-6',
    disabled: true,
    source,
    label: CARApplyPrefix(label, CAR_STEP_PREFIX),
    onChange,
    ignoreTranslateOptions,
    ignoreDropdownAppend: true,
    translatePrefix,
    bindValue,
    icon: {
      text: tooltip,
      tooltipPosition: 'left',
    }
  });
};

export const getFuel = (source: BehaviorSubject<any>, onChange?: (event) => void, label = CAR_FUEL_FLD, required = true, disabledExpression?: () => boolean,tooltip?:string): IRawForm => {
 if(tooltip)
 {
  return getDictSelect({
    id: CAR_FUEL_ID_FLD,
    className: 'col-6',
    disabledExpression,
    source,
    label: CARApplyPrefix(label, CAR_STEP_PREFIX),
    onChange,
    ignoreTranslateOptions: true,
    ignoreDropdownAppend: true,
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    required,
    icon: {
      text: tooltip,
      tooltipPosition: 'left',
    }
  });
}
else{
  return getDictSelect({
    id: CAR_FUEL_ID_FLD,
    className: 'col-6',
    disabledExpression,
    source,
    label: CARApplyPrefix(label, CAR_STEP_PREFIX),
    onChange,
    ignoreTranslateOptions: true,
    ignoreDropdownAppend: true,
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    required,
    // icon: {
    //   text: tooltip,
    //   tooltipPosition: 'left',
    // }
  });
}
};

export const getAlphanumericIDofCI = (
  source: BehaviorSubject<any>,
  onChange?: (event) => void,
  required = true,
  className = 'col-12',
  tooltip?:string,
  disabledExpression?: () => boolean,
  label = CAR_CI_ID_FLD,
  disabled?
): IRawForm => {
  if(tooltip){
    return getDictSelect({
      id: `${CAR_ALPHANUMERIC_CI_FLD}${disabled?'_ro':''}`,
      key:CAR_ALPHANUMERIC_CI_FLD,
      className,
      source,
      label: CARApplyPrefix(label, CAR_STEP_PREFIX),
      onChange,
      ignoreTranslateOptions: true,
      ignoreDropdownAppend: true,
      translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
      bindValue: 'name',
      required,
      disabledExpression: disabledExpression,
      icon: {
        text: tooltip,
        tooltipPosition: 'left',
      }
    });
  }
else{
    return getDictSelect({
      id: CAR_ALPHANUMERIC_CI_FLD,
      className,
      source,
      label: CARApplyPrefix(label, CAR_STEP_PREFIX),
      onChange,
      ignoreTranslateOptions: true,
      ignoreDropdownAppend: true,
      translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
      bindValue: 'name',
      required,
      disabledExpression: disabledExpression,
  });
}
};

export const getFacilityDifferentFromBiogas =(onChange: ()=>void,disabled?): IRawForm => {
  return getRadio({
      id: `${CAR_FACILITY_DIFFERENT_FROM_BIOGAS}${disabled?'_ro':''}`,
      key:CAR_FACILITY_DIFFERENT_FROM_BIOGAS,
      className: 'col-12',
      label: CARApplyPrefix(CAR_FACILITY_DIFFERENT_FROM_BIOGAS, CAR_STEP_PREFIX),
      options: [{key: true, value: `${CCRApplyPrefix('yes', CAR_STEP_PREFIX)}`}, {key: false, value: `${CCRApplyPrefix('no', CAR_STEP_PREFIX)}`}],
      dummyInputForLabel: true,
      onChange,
      required: true
  })
}

export const getElectricityFacilityName =(hideExpression?,tooltip?:string): IRawForm => {
  return getInput({
    id: randomStr(),
    key: CAR_ELECTRICITY_FACILITY_NAME,
    className: 'col-12',
    hideExpression: hideExpression,
    requiredExpression: `model.${CAR_FACILITY_DIFFERENT_FROM_BIOGAS} === true`,
    label: CARApplyPrefix(CAR_ELECTRICITY_FACILITY_NAME, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    icon: {
      text: CARApplyPrefix(tooltip,'.tooltip'),
    }
  });
};

export const getAgreementIdentifier = (source: BehaviorSubject<any>, onChange?: (event) => void, required = true): IRawForm => {
  return getDictSelect({
    id: CAR_AGREEMENT_IDENTIFIER,
    className: 'col-6',
    source,
    label: CARApplyPrefix(CAR_AGREEMENT_IDENTIFIER, CAR_STEP_PREFIX),
    onChange,
    ignoreTranslateOptions: true,
    ignoreDropdownAppend: true,
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    bindValue: 'dataId',
    bindLabel: 'code',
    required,
  });
};

export const getApprovedCIofLoCIFuelDropdown = (source: BehaviorSubject<any>, label = CAR_CI_LIQUID_CI_FUEL_FLD, id = CAR_CI_LIQUID_CI_FUEL_FLD, onChange?: () => void, className = 'col-6',tooltip?:string,disabled?): IRawForm => {
  return getDictSelect({
    id : `${id}${disabled?'_ro':''}`,
    key:id,
    className,
    source,
    label: CARApplyPrefix(label, CAR_STEP_PREFIX),
    ignoreTranslateOptions: true,
    ignoreDropdownAppend: true,
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    onChange,
    bindLabel: 'label',
    icon: {
      text: CARApplyPrefix(tooltip,CAR_STEP_PREFIX),
    }
  });
};

export const getAlphanumericID =(label = CAR_ALPHANUMERIC_CI_FLD, id=CAR_ALPHANUMERIC_CI_FLD, required=true): IRawForm => {
  return getInput({
    id,
    className: 'col-6',
    disabled: true,
    required,
    label: CARApplyPrefix(label, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    ignoreLocalFormDisabled: true,
  });
}

export const getCarbonIntensity =(label=CAR_CARBON_INTENSITY_OF_FUEL,id=CAR_CI_LIQUID_CI_FUEL_FLD,subPrefix='.reportStep' , required= true): IRawForm => {
  return getInput({
    id: `${id}_${label}`,
    key: id,
    className: 'col-6',
    disabled: true,
    required,
    label: 'CREDIT_CREATION_REPORT.ccrAdjustment_STEP-SUB_SEC_90.carbonIntensityUnderSection',
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    ignoreLocalFormDisabled: true,
  });
}

export const getQuantityOfFuel = (onChange?: () => void, label = CAR_QUANTITY_OF_FUEL, tooltip: string = null): IRawForm => {
  return getInput({
    id: CAR_FUEL_QUANTITY_FLD,
    className: 'col-6',
    customTemplateOptions: {
      onChange,
      type: 'formatted-number',
      allowNegativeNumbers: false,
    },
    min: 0,
    label: CARApplyPrefix(label, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    icon: {
      text: CARApplyPrefix(tooltip, CAR_STEP_PREFIX),
    },
    validators: ['positiveOrZeroNumber'],
  });
};

export const getNumberOfCreditsCreatedforParticipant = (label = CAR_NUMBER_OF_CREDITS_OF_PARTICIPANT, id = CAR_LIQUID_COMPLIANCE_CREDITS_FLD, disabled = true ): IRawForm => {
  return getInput({
    id,
    className: 'col-6',
    required: false,
    disabled,
    label: CARApplyPrefix(label, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    customTemplateOptions: {
      type: 'number',
      hideArrows: true,
    },
    min: 0,
    validators: ['positiveOrZeroNumber'],
    icon: {
      text: CREDITS_CREATED_FOR_PARTICIPANT_STEP7_TOOLTIP,
      tooltipPosition: 'left',
    }
  });
};

export const getNumberOfCreditsCreatedforZeroVolume = (label = CAR_NUMBER_OF_CREDITS_OF_PARTICIPANT,hideExpression: () => boolean, id = CAR_LIQUID_COMPLIANCE_CREDITS_FLD, disabled = true ): IRawForm => {
  return getInput({
    id,
    className: 'col-6',
    required: false,
    label: CARApplyPrefix(label, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    hideExpression: hideExpression,

    customTemplateOptions: {
      type: 'number',
      hideArrows: true,

    },
    validators: ['positiveOrZeroNumber'],
    icon: {
      text: CREDITS_CREATED_FOR_PARTICIPANT_STEP7_TOOLTIP,
      tooltipPosition: 'left',
    }
  });
};


export const getApprovedCIofLoCIFuel = (label = CAR_CI_LIQUID_CI_FUEL_FLD, id = CAR_CI_LIQUID_CI_FUEL_FLD, required = true,tooltip?,className ='col-12 no-margin',disabled?): IRawForm => {
  return getInput({
    id : `${id}${disabled?'_ro':''}`,
    key:id,
    className: className,
    type: 'readonly-input',
    required,
    label: CARApplyPrefix(label, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    ignoreLocalFormDisabled: true,
    icon: {
      text: tooltip,
      tooltipPosition: 'left',
    }
  });
};

export const getFeedstockType = (disabledExpression?: () => boolean,tooltip?, keyToIdentifyLabel: string=''): IRawForm => {
  return getInput({
    id: `${CAR_FEEDSTOCK_NAME_FLD}_${keyToIdentifyLabel}`,
    key: CAR_FEEDSTOCK_NAME_FLD,
    className: 'col-12',
    hideExpression: disabledExpression,
    label: CARApplyPrefix(CAR_FEEDSTOCK_NAME_FLD, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    icon: {
      text: tooltip,
      tooltipPosition: 'left',
    },
  });
};
export const getReadonlyFeedstockType = (disabledExpression?: () => boolean, tooltip?): IRawForm => {
  return getInput({
    id: `${ CAR_FEEDSTOCK_NAME_FLD }_ro`,
    key: CAR_FEEDSTOCK_NAME_FLD,
    type: 'readonly-input',
    className: 'col-12',
    hideExpression: disabledExpression,
    label: CARApplyPrefix(CAR_FEEDSTOCK_NAME_FLD, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    icon: {
      text: tooltip,
      tooltipPosition: 'left',
    },
  });
};

export const getFeedstockRegion = (disabledExpression?: () => boolean,tooltip?, keyToIdentifyLabel: string = ''): IRawForm => {
  return getInput({
    id: `${CAR_FEEDSTOCK_REGION_FLD}_${keyToIdentifyLabel}`,
    key: CAR_FEEDSTOCK_REGION_FLD,
    className: 'col-12',
    hideExpression: disabledExpression,
    label: CARApplyPrefix(CAR_FEEDSTOCK_REGION_FLD, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    icon: {
      text: tooltip,
      tooltipPosition: 'left',
    },
  });
};
export const getReadonlyFeedstockRegion = (disabledExpression?: () => boolean,tooltip?): IRawForm => {
  return getInput({
    id: `${ CAR_FEEDSTOCK_REGION_FLD }_ro`,
    key: CAR_FEEDSTOCK_REGION_FLD,
    className: 'col-12',
    type: 'readonly-input',
    hideExpression: disabledExpression,
    label: CARApplyPrefix(CAR_FEEDSTOCK_REGION_FLD, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    icon: {
      text: tooltip,
      tooltipPosition: 'left',
    }
  });
};
export const getEnergyDensityInput = (source: BehaviorSubject<any>, onChange?: () => void, label = CAR_LO_CI_FUEL_ENERGY_DENSITY_FLD, col6?:boolean,tooltip?,disabled?,key?): IRawForm =>{
  if(tooltip){
    return getInput({
      id:  `${CAR_ENERGY_DENSITY_INPUT_LABEL}${disabled?'_ro':''}`,
      key: key,
      className: col6? 'col-6':'col-12',
      label: CARApplyPrefix(label, CAR_STEP_PREFIX),
      translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
      hideExpression: `model.${CAR_MANUAL_ENERGY_DENSITY}`,
      icon: {
        text: tooltip,
        tooltipPosition: 'left',
      },
    });
  }
}

export const getEnergyDensity = (source: BehaviorSubject<any>, onChange?: () => void, label = CAR_LO_CI_FUEL_ENERGY_DENSITY_FLD, col6?:boolean,tooltip?,disabled?): IRawForm => {
 if(tooltip)
 {
  return getDictSelect({
    id: `${CAR_LO_CI_FUEL_ENERGY_DENSITY_FLD}${disabled?'_ro':''}`,
    key: 'energyDensityCode',
    className: col6? 'col-6':'col-12',
    source,
    label: CARApplyPrefix(label, CAR_STEP_PREFIX),
    onChange,
    bindValue: 'code',
    ignoreTranslateOptions: true,
    ignoreDropdownAppend: true,
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    hideExpression: `model.${CAR_MANUAL_ENERGY_DENSITY}`,
    icon: {
      text: tooltip,
      tooltipPosition: 'left',
    }
  });
}
else
{
  return getDictSelect({
    id: `${CAR_LO_CI_FUEL_ENERGY_DENSITY_FLD}${disabled?'_ro':''}`,
    key: 'energyDensityCode',
    className: col6? 'col-6':'col-12',
    source,
    label: CARApplyPrefix(label, CAR_STEP_PREFIX),
    onChange,
    bindValue: 'code',
    ignoreTranslateOptions: true,
    ignoreDropdownAppend: true,
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    hideExpression: `model.${CAR_MANUAL_ENERGY_DENSITY}`,
  });
}
};

export const getQuantityOfLoCIFuel = (onChange?: () => void, label = CAR_LO_CI_FUEL_QUANTITY_FLD, col6? :boolean, tooltip?: string, keyToIdentifyLabel: string = ''): IRawForm => {
  if(tooltip)
 {
  return getInput({
    id: `${CAR_LO_CI_FUEL_QUANTITY_FLD}_${keyToIdentifyLabel}`,
    key: CAR_LO_CI_FUEL_QUANTITY_FLD,
    className: col6 ? 'col-6': 'col-12',
    customTemplateOptions: {
      onChange,
      type: 'formatted-number',
      allowNegativeNumbers: false,
    },
    min: 0,
    validators: ['positiveOrZeroNumber'],
    label: CARApplyPrefix(label, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    icon: {
      text: tooltip,
      tooltipPosition: 'left',
    },
    disabledExpression: 'model.sameVolumeAsReportedInCCR === true',
  });
}
else
{
  return getInput({
    id: `${CAR_LO_CI_FUEL_QUANTITY_FLD}_${keyToIdentifyLabel}`,
    key: CAR_LO_CI_FUEL_QUANTITY_FLD,
    className: col6 ? 'col-6': 'col-12',
    customTemplateOptions: {
      onChange,
      type: 'formatted-number',
      allowNegativeNumbers: false,
    },
    min: 0,
    validators: ['positiveOrZeroNumber'],
    label: CARApplyPrefix(label, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    disabledExpression: 'model.sameVolumeAsReportedInCCR === true',
  });
}
};

export const getElectricalEnergyQuantityDifference = (label = CAR_BIOGAS_ENERGY_OUTPUT_DIFF,tooltip?:string, keyToIdentifyLabel = ''): IRawForm => {
  if(tooltip){
    return getInput({
      id: `${CAR_BIOGAS_ENERGY_OUTPUT_DIFF}_${keyToIdentifyLabel}`,
      key: CAR_BIOGAS_ENERGY_OUTPUT_DIFF,
      className: 'col-6',
      customTemplateOptions: {
        type: 'formatted-number',
        allowNegativeNumbers: false,
      },
      disabled: true,
      min: 0,
      label: CARApplyPrefix(label, CAR_STEP_PREFIX),
      translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
      hideExpression: 'model.ccr == true',
      icon: {
        text: CARApplyPrefix(tooltip, ".tooltip"),
        tooltipPosition: 'left',
      }
    });
  }
  else{
  return getInput({
    id: `${CAR_BIOGAS_ENERGY_OUTPUT_DIFF}_${keyToIdentifyLabel}`,
    key: CAR_BIOGAS_ENERGY_OUTPUT_DIFF,
    className: 'col-6',
    customTemplateOptions: {
      type: 'formatted-number',
      allowNegativeNumbers: false,
    },
    disabled: true,
    min: 0,
    label: CARApplyPrefix(label, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    hideExpression: 'model.ccr == true',
  });
}
}

export const getElectricalEnergyProducedQuantityDiff = (label = CAR_BIOGAS_ENERGY_PRODUCED_DIFF ,tooltip?:string, keyToIdentifyLabel = ''): IRawForm => {
  if(tooltip){
    return getInput({
      id: `${CAR_BIOGAS_ENERGY_PRODUCED_DIFF}_${keyToIdentifyLabel}`,
      key: CAR_BIOGAS_ENERGY_PRODUCED_DIFF,
      className: 'col-12',
      customTemplateOptions: {
        type: 'formatted-number',
        allowNegativeNumbers: false,
      },
      disabled: true,
      min: 0,
      label: CARApplyPrefix(label, CAR_STEP_PREFIX),
      translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
      hideExpression: 'model.ccr == true || model.section === \'SECTION_95\' || !model.section',
      icon: {
        text: CARApplyPrefix(tooltip, ".tooltip"),
        tooltipPosition: 'left',
      }
    });
  }
  else{
  return getInput({
    id: `${CAR_BIOGAS_ENERGY_PRODUCED_DIFF}_${keyToIdentifyLabel}`,
    key: CAR_BIOGAS_ENERGY_PRODUCED_DIFF,
    className: 'col-12',
    customTemplateOptions: {
      type: 'formatted-number',
      allowNegativeNumbers: false,
    },
    disabled: true,
    min: 0,
    label: CARApplyPrefix(label, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    hideExpression: 'model.ccr == true || model.section === \'SECTION_95\'',
  });
}
}

export const getQuantityDiff = (label = CAR_LO_CI_FUEL_QUANTITY_DIFF_FLD,tooltip?:string, keyToIdentifyLabel: string = ''): IRawForm => {
  if(tooltip){
    return getInput({
      id: `${CAR_LO_CI_FUEL_QUANTITY_DIFF_FLD}_${keyToIdentifyLabel}`,
      key: CAR_LO_CI_FUEL_QUANTITY_DIFF_FLD,
      className: 'col-12',
      customTemplateOptions: {
        type: 'formatted-number',
        allowNegativeNumbers: false,
      },
      disabled: true,
      min: 0,
      label: CARApplyPrefix(label, CAR_STEP_PREFIX),
      translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
      hideExpression: 'model.ccr == true',
      icon: {
        text: tooltip,
        tooltipPosition: 'left',
      }
    });
  }
  else{
  return getInput({
    id: `${CAR_LO_CI_FUEL_QUANTITY_DIFF_FLD}_${keyToIdentifyLabel}`,
    key: CAR_LO_CI_FUEL_QUANTITY_DIFF_FLD,
    className: 'col-12',
    customTemplateOptions: {
      type: 'formatted-number',
      allowNegativeNumbers: false,
    },
    disabled: true,
    min: 0,
    label: CARApplyPrefix(label, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    hideExpression: 'model.ccr == true',
  });
}
}

export const getNumberOfCreditsCreated = (): IRawForm => {
  return getInput({
    id: CAR_LO_CI_FUEL_QUANTITY_FLD,
    className: 'col-6',
    label: CARApplyPrefix(CAR_LO_CI_FUEL_QUANTITY_FLD, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    min: 0,
  });
};

export const getFuelReplacementType = (source: BehaviorSubject<any>, onChange?: () => void ,col6?:boolean,tooltip?): IRawForm => {
  if(tooltip){
    return getDictSelect({
      id: CAR_FUEL_REPLACEMENT_FLD,
      className: col6 ? 'col-6':'col-12',
      source,
      label: CARApplyPrefix(CAR_FUEL_REPLACEMENT_TYPE, CAR_STEP_PREFIX),
      onChange,
      ignoreTranslateOptions: true,
      ignoreDropdownAppend: true,
      translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
      hideExpression: 'model.fuelClass === \'GAS\'',
      icon: {
       text: tooltip,
        tooltipPosition: 'left',
      }
    });
  }
  else{
    return getDictSelect({
      id: CAR_FUEL_REPLACEMENT_FLD,
      className: col6 ? 'col-6':'col-12',
      source,
      label: CARApplyPrefix(CAR_FUEL_REPLACEMENT_TYPE, CAR_STEP_PREFIX),
      onChange,
      ignoreTranslateOptions: true,
      ignoreDropdownAppend: true,
      translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
      hideExpression: 'model.fuelClass === \'GAS\'',
    });
  }

};

export const getAnnualCO2FuelReplacementType = (source: BehaviorSubject<any>, onChange?: () => void): IRawForm => {
  return getDictSelect({
    id: CAR_FUEL_REPLACEMENT_FLD,
    className: 'col-6',
    source,
    label: CARApplyPrefix(CAR_FUEL_REPLACEMENT_TYPE, CAR_STEP_PREFIX),
    onChange,
    ignoreTranslateOptions: true,
    ignoreDropdownAppend: true,
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    hideExpression: 'model._replacementHidden === true',
  });
};

export const getCreditType = (source: BehaviorSubject<any>, onChange?: () => void,col6?:boolean,tooltip?:string,disabled?): IRawForm => {
  return getDictSelect({
    id: `${CAR_CREDITS_TYPE_FLD}${disabled?'_ro':''}`,
    key: CAR_CREDITS_TYPE_ID,
    className: col6 ? 'col-6':'col-12',
    source,
    disabled: true,
    label: CARApplyPrefix(CAR_CREDITS_TYPE_FLD, CAR_STEP_PREFIX),
    onChange,
    ignoreTranslateOptions: true,
    ignoreDropdownAppend: true,
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
  });
};

export const getTypeOfCredits = (): IRawForm => {
  return getReadOnly({
    id: CAR_CREDITS_TYPE_FLD,
    className: 'col-6',
    required: false,
    label: CARApplyPrefix(CAR_CREDITS_TYPE_FLD, CAR_STEP_PREFIX),
    translate: true,
    translatePrefix: 'COMMON.creditClassList',
  });
};

export const getLiquidComplianceCreditsCreated = (label = CAR_LIQUID_COMPLIANCE_CREDITS_FLD, id = CAR_LIQUID_COMPLIANCE_CREDITS_FLD, disabled = true, min?: number): IRawForm => {
  return getInput({
    id:`${id}${disabled?'_ro':''}`,
    key:id,
    className: 'col-12',
    required: false,
    disabled,
    label: CARApplyPrefix(label, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    customTemplateOptions: {
      type: 'number',
      hideArrows: true,
    },
    min: 0,
  });
};

export const getProvisionalLiquidComplianceCreditsCreated = (label = CAR_ANNUAL_COMPLIANCE_CREDIT_CREATED_VALUE, id = CAR_ANNUAL_CO2_LIQUID_CREDITS): IRawForm => {
  return getInput({
    id,
    className: 'col-6',
    required: false,
    label: CARApplyPrefix(label, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    customTemplateOptions: {
      mask: 'separator.5',
      type: 'formatted-number',
      hideArrows: true,
    },
    min: 0,
  });
};

export const getProvisionalGaseousComplianceCreditsCreated = (label = CAR_ANNUAL_LIQUID_COMPLIANCE_CREDITS_GAS_LABEL, id = CAR_ANNUAL_CO2_GASEOUS_CREDITS): IRawForm => {
  return getInput({
    id,
    className: 'col-6',
    required: false,
    label: CARApplyPrefix(label, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    customTemplateOptions: {
      mask: 'separator.5',
      type: 'formatted-number',
      hideArrows: true,
    },
    min: 0,
  });
};

export const getOverrideCreditsSection = (disabled = false,tooltip?:string): IRawForm[] => {
  return [
    getCheckbox({
      id: `${CAR_OVERRIDE_CREDITS_NUMBER_FLD}${disabled ? '_ro' : ''}`,
      key: CAR_OVERRIDE_CREDITS_NUMBER_FLD,
      className: 'col-12',
      label: CARApplyPrefix(CAR_OVERRIDE_CREDITS_NUMBER_FLD, CAR_STEP_PREFIX),
      required: false,
    }),
    getInput({
      id: `${CAR_COMPLIANCE_CREDIT_CREATED_VALUE}${disabled ? '_ro' : ''}`,
      key: CAR_COMPLIANCE_CREDIT_CREATED_VALUE,
      className: 'col-12',
      label: CARApplyPrefix(CAR_UPDATED_COMPLIANCE_CREDIT_VALUE, CAR_STEP_PREFIX),
      translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
      hideExpression: `!model.${ CAR_OVERRIDE_CREDITS_NUMBER_FLD }`,
      customTemplateOptions: {
        type: 'formatted-number',
        hideArrows: true,
      },
      min: 0,
    }),
    getTextArea({
      id: `${CAR_REASON_FOR_OVERRIDING_FLD}${disabled ? '_ro' : ''}`,
      key: CAR_REASON_FOR_OVERRIDING_FLD,
      className: 'col-12',
      label: CARApplyPrefix(CAR_REASON_FOR_OVERRIDING_FLD, CAR_STEP_PREFIX),
      translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
      hideExpression: `!model.${ CAR_OVERRIDE_CREDITS_NUMBER_FLD }`,
    }),
  ];
};

export const getCISearchFields = (hideExpression: () => boolean, searchCallback: () => void, clearCallback: () => void, keyToIdentifyLabel: string = '',className ?): IRawForm[] => {
  return [
    getInput({
      id: `${CAR_CI_ID}_${keyToIdentifyLabel}`,
      key: CAR_CI_ID,
      className: className? className :'col-12',
      hideExpression,
      label: CARApplyPrefix(CAR_CI_ID, CAR_STEP_PREFIX),
      placeholder: CARApplyPrefix(CAR_CI_ID, CAR_STEP_PREFIX),
      translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    }),
    getButton({
      id: CAR_SEARCH_BUTTON,
      label: CARApplyPrefix(CAR_SEARCH_BUTTON, CAR_STEP_PREFIX),
      className: 'col-0 mx-3',
      hideExpression,
      customTemplateOptions: {
        onClick: searchCallback,
      },
    }),
    getButton({
      id: CAR_CLEAR_BUTTON,
      label: CARApplyPrefix(CAR_CLEAR_BUTTON, CAR_STEP_PREFIX),
      className: 'col-0',
      hideExpression,
      customTemplateOptions: {
        onClick: clearCallback,
      },
    }),
  ];
};

export const getEnergyEfficiencyRatio = (source: BehaviorSubject<any>, onChange?: () => void): IRawForm => {
  return getDictSelect({
    id: CAR_ENERGY_EFFICIENCY_RATIO,
    className: 'col-6',
    source,
    label: CARApplyPrefix(CAR_ENERGY_EFFICIENCY_RATIO, CAR_STEP_PREFIX),
    disabled: false,
    ignoreTranslateOptions: true,
    ignoreDropdownAppend: true,
    onChange,
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    bindValue: 'fuelValue',
  });
};


export const getTotalComplianceCreditsToBeDeposited = (): IRawForm => {
  return getInput({
    id: CAR_COMPLIANCE_CREDITS_DEPOSITED_FINAL,
    className: 'col-6',
    required: false,
    type: RAW_FORM_TYPE_READONLY_INPUT,
    label: CARApplyPrefix(CAR_COMPLIANCE_CREDITS_TO_BE_DEPOSITED_FLD, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    translate: false,
    customTemplateOptions: {
      type: 'formatted-number',
      allowNegativeNumbers: false,
    }
  });
};

export const getTotalComplianceCreditsToBeCancelled = (): IRawForm => {
  return getInput({
    id: CAT_COMPLIANCE_CREDITS_CANCELLED_FINAL,
    className: 'col-6',
    required: false,
    type: RAW_FORM_TYPE_READONLY_INPUT,
    label: CARApplyPrefix(CAR_COMPLIANCE_CREDITS_TO_BE_CANCELLED_FLD, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    translate: false,
    customTemplateOptions: {
      type: 'formatted-number',
      allowNegativeNumbers: false,
    }
  });
};

export const getDescriptionOfChangeOrError = (requiredExpression: () => boolean): IRawForm => {
  return getTextArea({
    id: CAR_DESCRIPTION_OF_CHANGE_OR_ERROR,
    className: 'col-12',
    required: false,
    label: CARApplyPrefix(CAR_DESCRIPTION_OF_CHANGE_OR_ERROR, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    requiredExpression,
  });
};


export const getTotalGasolineReplacement = (): IRawForm => {
  return getInput({
    id: CAR_TOTAL_LIQUID_GASOLINE_CREDITS_CREATED,
    className: 'col-6',
    required: false,
    type: RAW_FORM_TYPE_READONLY_INPUT,
    label: CARApplyPrefix(CAR_TOTAL_LIQUID_GASOLINE_CREDITS_CREATED, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
  });
};

export const getTotalDieselReplacement = (): IRawForm => {
  return getInput({
    id: CAR_TOTAL_LIQUID_DIESEL_CREDITS_CREATED,
    className: 'col-6',
    required: false,
    type: RAW_FORM_TYPE_READONLY_INPUT,
    label: CARApplyPrefix(CAR_TOTAL_LIQUID_DIESEL_CREDITS_CREATED, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
  });
};

export const getTotalOtherLiquidReplacement = (): IRawForm => {
  return getInput({
    id: CAR_TOTAL_LIQUID_OTHER_CREDITS_CREATED,
    className: 'col-6',
    required: false,
    type: RAW_FORM_TYPE_READONLY_INPUT,
    label: CARApplyPrefix(CAR_TOTAL_LIQUID_OTHER_CREDITS_CREATED, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
  });
};

export const getTotalLiquidReplacement = (label = CAR_TOTAL_TOTAL_LIQUID_CREDITS_CREATED ): IRawForm => {
  return getInput({
    id: CAR_TOTAL_TOTAL_LIQUID_CREDITS_CREATED,
    className: 'col-6',
    required: false,
    type: RAW_FORM_TYPE_READONLY_INPUT,
    label: CARApplyPrefix(label, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
  });
};

export const getTotalGaseousComplianceCredits = (): IRawForm => {
  return getInput({
    id: CAR_TOTAL_GASEOUS_CREDITS_CREATED,
    className: 'col-6',
    required: false,
    type: RAW_FORM_TYPE_READONLY_INPUT,
    label: CARApplyPrefix(CAR_TOTAL_GASEOUS_CREDITS_CREATED, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
  });
};

export const getfaciltiyCivicAddressConfig = (
  key = CAR_CIVIC_ADDRESS,
  label = CAR_FACILITY_CIVIC_ADDRESS,
  disabled: any = false,
  sameAsPostal = {hide: false},
  noLatLong = false,
  tooltip?:string,
  className = 'col-12 p-2',
  optional =true
  ) => {
  return {
    key,
    label: CARApplyPrefix(label, CAR_STEP_PREFIX),
    sameAsOrganization: {
      hide: true,
    },
    hidePoBox: true,
    sameAsPostal,
    disabled,
    hideLatLong: noLatLong,
    requiredLatLong: !noLatLong,
    className: className,
    optional: optional,
    tooltip
  };
};
export const getfaciltiyPostalAddressConfig = (key = CAR_POSTAL_ADDRESS, label = CAR_POSTAL_ADDRESS, changeExpr?,tooltip?) => {
  return {
    key,
    changeExpr,
    label: CARApplyPrefix(label, CAR_STEP_PREFIX),
    sameAsOrganization: {
      hide: true,
    },
    hidePoBox: false,
    sameAsPostal: {
      hide: true,
    },
    provinceAsFreeTextExpr: '![\'US\', \'CA\'].includes(this.model.country)',
    hideLatLong: true,
    tooltip

  };
};

export const getQuarters = (hideExpression: () => boolean, onChange: (event?) => void,tooltip?:string): IRawForm => {
  return getDictSelect({
    id: CAR_QUARTER_FLD,
    className: 'col-6 mr-5',
    source: SOURCE_DATA_TYPE.CCR_QUARTERS,
    dataType: '2022',
    label: CARApplyPrefix(CAR_QUARTER_FLD, CAR_STEP_PREFIX),
    bindValue: 'code',
    disabled: false,
    ignoreTranslateOptions: true,
    ignoreDropdownAppend: true,
    hideExpression,
    onChange,
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    icon: {
      text: tooltip,
    }
  });
};

export const getFuelingStationRegisteredRadio = (onChange: () => void, disabled = false): IRawForm => {
  return getRadio({
      id: `${CAR_FUELING_STATION_REGISTERED}${disabled ? '_ro' : ''}`,
      key: CAR_FUELING_STATION_REGISTERED,
      className: 'col-12',
      label: CARApplyPrefix(CAR_FUELING_STATION_REGISTERED, CAR_STEP_PREFIX),
      options: [{key: true, value: `${CARApplyPrefix('yes', CAR_STEP_PREFIX)}`}, {key: false, value: `${CARApplyPrefix('no', CAR_STEP_PREFIX)}`}],
      onChange,
      disabled,
      dummyInputForLabel: true,
      required: true,
    });
};

export const getAccessibilityToMarineVesselsRadio = (onChange?: () => void): IRawForm => {
  return getRadio({
      id: CAR_ACCESSIBILITY_TO_MARINE_VESSELS,
      className: 'col-6 inline align-self-start',
      label: CARApplyPrefix(CAR_ACCESSIBILITY_TO_MARINE_VESSELS, CAR_STEP_PREFIX),
      options: [{key: true, value: `${CARApplyPrefix('yes', CAR_STEP_PREFIX)}`}, {key: false, value: `${CARApplyPrefix('no', CAR_STEP_PREFIX)}`}],
      onChange,
      dummyInputForLabel: true,
      required: true,
    });
};

export const getRegisteredFuelingStationFields = (hideExpression: (model: any) => boolean, searchCallback: () => void, clearCallback: () => void, tooltip?: string): IRawForm[] => {
  return [
    getInput({
      id: CAR_ORGNIZATION_ID,
      className: 'col-6',
      required: true,
      hideExpression,
      disabled: true,
      label: CARApplyPrefix(CAR_ORGNIZATION_ID, CAR_STEP_PREFIX),
      placeholder: CARApplyPrefix(CAR_ORGNIZATION_ID_PLACEHOLDER, CAR_STEP_PREFIX),
      translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    }),
    getInput({
      id: CAR_STATION_ID,
      className: 'col-6',
      required: true,
      hideExpression,
      disabled: true,
      label: CARApplyPrefix(CAR_STATION_ID, CAR_STEP_PREFIX),
      placeholder: CARApplyPrefix(CAR_STATION_ID_PLACEHOLDER, CAR_STEP_PREFIX),
      translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    }),
  ];
};

export const getFuelingStationOwnerName = (tooltip?:string): IRawForm => {
  return  getInput({
    id: CAR_FUELING_STATION_OWNER_NAME,
    className: 'col-6',
    required: true,
    label: CARApplyPrefix(CAR_FUELING_STATION_OWNER_NAME_LABEL, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    hideExpression: `typeof (model.${CAR_FUELING_STATION_REGISTERED}) !== 'boolean'`,
    disabled: true,
    ignoreLocalFormDisabled: true,
    icon: {
      text: tooltip,
    }
  });
};

export const getFuelingStationName = (tooltip?:string): IRawForm => {
  return getInput({
    id: CAR_FUELING_STATION_NAME,
    className: 'col-6',
    required: true,
    label: CARApplyPrefix(CAR_FUELING_STATION_NAME_LABEL, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    hideExpression: `typeof (model.${CAR_FUELING_STATION_REGISTERED}) !== 'boolean'`,
    disabled: true,
    icon: {
      text: tooltip,
    }
  });
};

export const getForeignSupplierName = (tooltip?:string, keyToIdentifyLabel: string= ''): IRawForm => {
  return getInput({
      id: `${CAR_FOREIGN_SUPPLIER_NAME}_${keyToIdentifyLabel}`,
      key: CAR_FOREIGN_SUPPLIER_NAME,
      className: 'col-6',
      required: true,
      label: CARApplyPrefix(CAR_FOREIGN_SUPPLIER_NAME, CAR_STEP_PREFIX),
      translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
      icon: {
        text: tooltip,
      }
    });
};

export const getEquipmentSerialNo = (disabledExpression?: () => boolean): IRawForm => {
  return getInput({
    id: CAR_EQUIPMENT_SERIAL_NO_FLD,
    className: 'col-6',
    disabledExpression,
    label: CARApplyPrefix(CAR_EQUIPMENT_SERIAL_NO_FLD, CAR_STEP_PREFIX),
    required: false,
  });
};

export const getTransferee = (): IRawForm => {
  return getInput({
    id: CAR_TRANSFEREE,
    className: 'col-6',
    disabled: true,
    label: CARApplyPrefix(CAR_TRANSFEREE, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
  });
};

export const getReadOnlyInputField = (label, id?, hideExpression? , tooltip?: string,disabled?): IRawForm => {
  if(tooltip)
  {
  return getInput({
    id: `${id || label}${disabled?'_ro':''}`,
    key: id,
    className: 'col-6',
    required: false,
    type: RAW_FORM_TYPE_READONLY_INPUT,
    label: CARApplyPrefix(label, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    hideExpression,
    icon: {
      text: tooltip,
    },
  });
}
else
{
  return getInput({
    id: `${id || label}${disabled?'_ro':''}`,
    key:id,
    className: 'col-6',
    required: false,
    type: RAW_FORM_TYPE_READONLY_INPUT,
    label: CARApplyPrefix(label, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    hideExpression,
  });
}
};

export const getValidationEquation = (hideExpression?,tooltip?:string): IRawForm => {
  return getInput({
    id: CAR_SECTION_VALIDATION_EQUATION,
    className: 'col-12',
    required: false,
    type: RAW_FORM_TYPE_READONLY_INPUT,
    translate: false,
    label: CARApplyPrefix(CAR_SECTION_VALIDATION_EQUATION, CAR_STEP_PREFIX),
    hideExpression,
  });
};

export const getProjectId = (source: BehaviorSubject<any>, onChange?: () => void, btnCallback?: () => void): IRawForm[] => {
  return [
    getDictSelect({
      id: CAR_PROJECT_ID_FLD,
      key: CAR_PROJECT_ID_FLD,
      className: 'col-6',
      source,
      label: CARApplyPrefix(CAR_PROJECT_ID_FLD, CAR_STEP_PREFIX),
      onChange,
      ignoreTranslateOptions: true,
      ignoreDropdownAppend: true,
      translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    }),
    getButton({
      id: CAR_VIEW_PROJECT_INFO,
      label: CARApplyPrefix(CAR_VIEW_PROJECT_INFO, CAR_STEP_PREFIX),
      className: 'col-6',
      customTemplateOptions: {
        onClick: btnCallback,
      },
      disabledExpression: `!model['${CAR_PROJECT_ID_FLD}']`,
    }),
  ];
};

export const getContactPersonInfo = (btnCallback?: () => void): IRawForm[] => {
  return [
    getInput({
      id: `${CAR_CONTACT_PERSON_NAME_FLD}.name`,
      className: 'col-6',
      disabled: true,
      label: CARApplyPrefix(CAR_CONTACT_PERSON_NAME_FLD, CAR_STEP_PREFIX),
      translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    }),
    getButton({
      id: CAR_VIEW_CONTACT_PERSON_INFO,
      label: CARApplyPrefix(CAR_VIEW_CONTACT_PERSON_INFO, CAR_STEP_PREFIX),
      className: 'col-6',
      customTemplateOptions: {
        onClick: btnCallback,
      },
      disabledExpression: `!model['${CAR_CONTACT_PERSON_NAME_FLD}']`,
    }),
  ];
};

export const getAddressDetails = (btnCallback?: () => void): IRawForm[] => {
  return [
    getButton({
      id: CAR_VIEW_ADDRESS,
      label: CARApplyPrefix(CAR_VIEW_ADDRESS, CAR_STEP_PREFIX),
      className: 'col-6',
      customTemplateOptions: {
        onClick: btnCallback,
      },
    }),
  ];
};

export const getStaticText = (label: string, className: string = 'col-12', translate: boolean = true, hideExpression?): IRawForm => {
  return getLabel({
    label,
    className,
    translate,
    hideExpression,
  });
};

export const getInputField = (
  id,
  onChange?: () => void,
  required = false,
  label = null,
  min?: number,
  hideExpression?,
  disabled?,
  tooltip?: string,
  className = 'col-6',
  disabledExpression?,
  key = id
  ): IRawForm => {

  const inputParams: Partial<IRawFormInput> = {
    id,
    key,
    className,
    required,
    disabled,
    label: CARApplyPrefix(label || id, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    customTemplateOptions: {
      onChange,
      type: 'formatted-number',
      hideArrows: true,
    },
    validators: ['positiveOrZeroNumber'],
    disabledExpression,
    min,
    hideExpression
  };

  if (tooltip) {
    inputParams.icon = {
      text: CARApplyPrefix(tooltip, CAR_STEP_PREFIX),
    };
  }
  return getInput(inputParams);
};

export const getTextInputField = (id, onChange?: () => void, required = false, label = null): IRawForm => {
  return getInput({
    id,
    className: 'col-6',
    required,
    label: CARApplyPrefix(label || id, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    customTemplateOptions: {
      onChange,
    },
  });
};

export const getReferenceCI = (source: BehaviorSubject<any>, onChange: () => void, hideExpression?, disabled?, tooltip?): IRawForm => {
  return getDictSelect({
    id: `${CAR_REFERENCE_CI_OF_ELECTRICITY_CODE_FLD}${disabled?'_ro':''}`,
    key: CAR_REFERENCE_CI_OF_ELECTRICITY_CODE_FLD,
    className: 'col-6 ',
    source,
    label: CARApplyPrefix(CAR_REFERENCE_CI_OF_ELECTRICITY, CAR_STEP_PREFIX),
    disabled: false,
    bindValue: 'code',
    ignoreTranslateOptions: true,
    ignoreDropdownAppend: true,
    onChange,
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    hideExpression,
  });
};

export const getSectionField = (onChange: () => void,tooltip?:string): IRawForm => {
  return getDictSelect({
    id: CAR_SECTION_FLD,
    className: 'col-12',
    source: SOURCE_DATA_TYPE.SECTION_FOR_CREDIT_CREATION,
    label: CARApplyPrefix(CAR_SECTION_FLD, CAR_STEP_PREFIX),
    disabled: false,
    ignoreTranslateOptions: false,
    ignoreDropdownAppend: true,
    onChange,
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
  });
};

export const getQuantityOfFuelToVehicles = (onChange?: () => void, label = CAR_QUANTITY_VEHICLE_FUEL_FLD): IRawForm => {
  return getInput({
    id: CAR_QUANTITY_VEHICLE_FUEL_FLD,
    className: 'col-6',
    customTemplateOptions: {
      onChange,
      type: 'number',
      hideArrows: true,
    },
    label: CARApplyPrefix(label, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    min: 0,
    validators: ['positiveOrZeroNumber'],
  });
};

export const getQuantityOfRenewableFuelToVehicles = (onChange?: () => void, label = CAR_QUANTITY_RENEWABLE_VEHICLE_FUEL_FLD): IRawForm => {
  return getInput({
    id: CAR_QUANTITY_RENEWABLE_VEHICLE_FUEL_FLD,
    className: 'col-6',
    customTemplateOptions: {
      onChange,
      type: 'number',
      hideArrows: true,
    },
    label: CARApplyPrefix(label, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    min: 0,
    validators: ['positiveOrZeroNumber'],
  });
};

export const getQuantityOfFuelSuppliedToVehicles = (label = CAR_QUANTITY_FUEL_SUPPLIED_TO_VEHICLE_FLD, id = CAR_QUANTITY_FUEL_SUPPLIED_TO_VEHICLE_FLD, disabled = true): IRawForm => {
  return getInput({
    id,
    className: 'col-6',
    required: false,
    disabled,
    label: CARApplyPrefix(label, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    customTemplateOptions: {
      type: 'number',
      hideArrows: true,
    },
    min: 0,
    validators: ['positiveOrZeroNumber'],
  });
};

export const getVolumeOfRenewableFuelToVehicles = (onChange?: () => void, label = CAR_VOLUME_RENEWABLE_VEHICLE_FUEL): IRawForm => {
  return getInput({
    id: CAR_QUANTITY_RENEWABLE_VEHICLE_FUEL_FLD,
    className: 'col-6',
    customTemplateOptions: {
      onChange,
      type: 'number',
      hideArrows: true,
    },
    label: CARApplyPrefix(label, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    min: 0,
    validators: ['positiveOrZeroNumber'],
  });
};

export const getQuantityOfHydrogen = (onChange?: () => void, label = CAR_QUANTITY_OF_HYDROGEN): IRawForm => {
  return getInput({
    id: CAR_LO_CI_FUEL_QUANTITY_FLD,
    className: 'col-6',
    customTemplateOptions: {
      onChange,
      type: 'number',
      hideArrows: true,
    },
    label: CARApplyPrefix(label, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    min: 0,
    validators: ['positiveOrZeroNumber'],
  });
};

export const getElectricEnergyOutput = (onChange?: () => void,tooltip?:string, keyToIdentifyLabel = ''): IRawForm => {
  return getInput({
    id: `${CAR_ELECTRICAL_ENERGY_OUTPUT}_${keyToIdentifyLabel}`,
    key: CAR_ELECTRICAL_ENERGY_OUTPUT,
    className: 'col-6',
    required: true,
    label: CARApplyPrefix(CAR_ELECTRICAL_ENERGY_OUTPUT, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
    customTemplateOptions: {
      onChange,
      type: 'number',
      hideArrows: true,
    },
    min: 0,
    validators: ['positiveOrZeroNumber'],
    icon: {
      text: ELECTRICITY_ENERGY_EQUIP_TOOLTIP,
      tooltipPosition: 'left',
    },
    disabledExpression: 'model.sameEnergyOutputAsReportedInCCR === true'
  });
};

export const getChargingStations = (source: BehaviorSubject<any>, onChange?: () => void, btnCallback?: () => void): IRawForm[] => {
  return [
    getDictSelect({
      id: CAR_CHARGING_STATION_ID_FLD,
      className: 'col-6',
      source,
      label: CARApplyPrefix(CAR_CHARGING_STATION_FLD, CAR_STEP_PREFIX),
      onChange,
      ignoreTranslateOptions: true,
      ignoreDropdownAppend: true,
      translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
      hideExpression: `!model.${ CAR_CI_ID_FLD }`,
    }),
    getInput({
      id: CAR_CHARGING_STATION_NAME_FLD,
      className: 'col-6',
      label: CARApplyPrefix(CAR_CHARGING_STATION_NAME_FLD, CAR_STEP_PREFIX),
      translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
      hideExpression: `model.${ CAR_CI_ID_FLD }`,
    }),
    getButton({
      id: CAR_VIEW_ADDRESS,
      label: CARApplyPrefix(CAR_VIEW_ADDRESS, CAR_STEP_PREFIX),
      className: 'col-6',
      customTemplateOptions: {
        onClick: btnCallback,
      },
      hideExpression: `!model.${ CAR_CI_ID_FLD }`,
    }),
    getButton({
      id: CAR_EDIT_ADDRESS,
      label: CARApplyPrefix(CAR_EDIT_ADDRESS, CAR_STEP_PREFIX),
      className: 'col-6',
      customTemplateOptions: {
        onClick: btnCallback,
      },
      hideExpression: `model.${ CAR_CI_ID_FLD }`,
    }),
  ];
};

export const fuelReplacementMap = {
  HDRD: 'DISREP',
  Eth: 'GASREP',
  BioD: 'DISREP',
};

export const getManualEnergyDensity = (change: () => void, disabled = false,tooltip?:string): IRawForm => {
  return getCheckbox({
    id: `${CAR_MANUAL_ENERGY_DENSITY}${disabled ? '_ro' : ''}`,
    className: 'col-6 ml-0 mb-2 ',
    label: CARApplyPrefix(CAR_MANUAL_ENERGY_DENSITY, CAR_STEP_PREFIX),
    required: false,
    change,
    icon: {
      text: tooltip,
    }
  });
};

export const getOpinioTypeRadio = (): IRawForm => {
  return getRadio({
      id: CAR_OPINION_TYPE,
      className: 'col-12 inline align-self-start',
      label: CARApplyPrefix(CAR_OPINION_TYPE, CAR_STEP_PREFIX),
      options: [
        {
          key: 'UNQUALIFIED', value: `${CARApplyPrefix('UNQUALIFIED', CAR_STEP_PREFIX)}`,
        },
        {
          key: 'QUALIFIED', value: `${CARApplyPrefix('QUALIFIED', CAR_STEP_PREFIX)}`,
        },
        {
          key: 'ADVERSE', value: `${CARApplyPrefix('ADVERSE', CAR_STEP_PREFIX)}`,
        },
        {
          key: 'DISCLAIM', value: `${CARApplyPrefix('DISCLAIM', CAR_STEP_PREFIX)}`,
        },
      ],
      dummyInputForLabel: true,
      required: true,
    });
};

export const getConclusionComment = (): IRawForm => {
  return getTextArea({
    id: CAR_CONCLUSION_COMMENT,
    className: 'col-12 mt-3',
    required: false,
    label: CARApplyPrefix(CAR_CONCLUSION_COMMENT, CAR_STEP_PREFIX),
    translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
  });
};

export const getYesNORadio = (id, hideExpression?): IRawForm => {
  return getRadio({
      id,
      className: 'col-12 inline align-self-start',
      label: CARApplyPrefix(id, CAR_STEP_PREFIX),
      options: [
        {
          key: true, value: `${CARApplyPrefix('yes', CAR_STEP_PREFIX)}`,
        },
        {
          key: false, value: `${CARApplyPrefix('no', CAR_STEP_PREFIX)}`,
        },
      ],
      dummyInputForLabel: true,
      required: true,
      hideExpression,
    });
};

export const getComplianceCreditsBlocksToBeCancelledTable = (
  config: ITableViewConfig,
  updateData: Subject<IApp.IPageable> = null,
  selections: any[] = null,
  onRowSelected: (data: any, background: any) => void = null,
  onPaginationChanged: (event: any) => void = null,
  requestSelected: Subject<any> = null,
  footerTemplate: TemplateRef<any> = null,
  disabled: boolean = false,
): IRawForm[] => {
  return [
    getLabel({
      label: CAR_STEP_5_TABLE_TITLE,
      className: 'col-12',
      hideExpression: CAR_STEP_5_CREDITS_HIDE_EXPRESSION,
    }),
    getTable({
      id: CAR_CREDITS_BLOCKS_TO_BE_CANCELLED_TABLE_ID,
      className: 'col-12 mb-4 table-h-500',
      customTemplateOptions: {
        config,
        updateData,
        footerTemplate,
        onRowSelected,
        onPaginationChanged,
        requestSelected,
        selections,
        disabled,
        disableOneRowSelection: true,
      },
      hideExpression: CAR_STEP_5_CREDITS_HIDE_EXPRESSION,
    }),
  ];
};
export const getCarbonIntensitiesUnderSectionTable =(
  id,
  config: ITableViewConfig,
  updateData: Subject<IApp.IPageable> = null,
  selections: any[] = null,
  onRowSelected: (data: any) => void = null,
  onPaginationChanged: (event: any) => void = null,
  requestSelected: Subject<any> = null,
  footerTemplate: TemplateRef<any> = null,
  disabled: boolean = false,
  // label: string,
  // tooltip: string = null,
  initialData: any = null,
  checkForSection,
  hideExpression: () => boolean,

):IRawForm[]=>{
  return [
    getLabel({
      label: checkForSection ? `${ CAR_PREFIX }${ CAR_UNDER_SECTION }.carbonIntensitiesTableLabel` : `${ CAR_PREFIX }${ CAR_UNDER_SECTION }.carbonIntensitiesTableLabel_only88`,
      className: 'col-12',
      hideExpression,
    }),
    getTable({
      id,
      className: 'col-12 mb-4 table-h-500',
      hideExpression,
      customTemplateOptions: {
        config,
        updateData,
        initialData,
        footerTemplate,
        onRowSelected,
        onPaginationChanged,
        requestSelected,
        selections,
        disabled,
        disableOneRowSelection: true,
      },
    }),
  ]
}

export const getFuelInput = (id,tooltip?:string, keyToIdentifyLabel: string= ''): IRawForm => {
  return getInput({
      id: id,
      className: 'col-6',
      required: true,
      label: CARApplyPrefix(CAR_FUEL_FLD, CAR_STEP_PREFIX),
      translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
      disabled:true
      // icon: {
      //   text: tooltip,
      // }
    });
};

export const getOrganization = (tooltip?:string, keyToIdentifyLabel: string= ''): IRawForm => {
  return getInput({
      id: 'legalName',
      className: 'col-6',
      required: true,
      label: CARApplyPrefix('organizationName', CAR_STEP_PREFIX),
      translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
      disabled:true,
      hideExpression:`model.isSameAccount`
      // icon: {
      //   text: tooltip,
      // }
    });
};

export const getAlphanumericIdInput = (tooltip?:string, keyToIdentifyLabel: string= ''): IRawForm => {
  return getInput({
      id: CAR_INSTRUMENT_CI_ID,
      className: 'col-6',
      required: true,
      label: CARApplyPrefix('ciApplicationId', CAR_STEP_PREFIX),
      translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
      disabled:true,
      icon: {
        text: tooltip,
      }
    });
};



export const getSameVolumeAsReportedInCCR = (change: () => void, disabled?): IRawForm => {
  return getCheckbox({
    id: `${CAR_SAME_QUANTITY_AS_IN_CCR}${disabled ? '_ro' : ''}`,
    key: CAR_SAME_QUANTITY_AS_IN_CCR,
    className: 'col-12',
    change,
    label: CARApplyPrefix(CAR_SAME_QUANTITY_AS_IN_CCR, CAR_STEP_PREFIX),
    required: false,
    hideExpression: 'model.ccr == true',
  });
};

export const getCorrectMistakeinQuarterly = (change:()=>void,disabled?):IRawForm =>{
  return getCheckbox({
    id: `${CAR_CI_ADJUSTED_SECTION_89}${disabled ? '_ro' : ''}`,
    key: CAR_CI_ADJUSTED_SECTION_89,
    className: 'col-12',
    change,
    label: CARApplyPrefix(CAR_CI_ADJUSTED_SECTION_89, CAR_STEP_PREFIX),
    required: false,
    hideExpression: 'model.ccr == true',
  });
}

export const getSameElectricalEnergyOutputAsReportedInCCR = (change: () => void, disabled?): IRawForm => {
  return getCheckbox({
    id: `${CAR_SAME_ENERGY_OUTPUT_AS_IN_CCR}${disabled ? '_ro' : ''}`,
    key: CAR_SAME_ENERGY_OUTPUT_AS_IN_CCR,
    className: 'col-12',
    change,
    label: CARApplyPrefix(CAR_SAME_QUANTITY_AS_IN_CCR, CAR_STEP_PREFIX),
    required: false,
    hideExpression: 'model.ccr == true',
  });
};

export const getSameElectricalEnergyProducedAsReportedInCCR = (change: () => void, disabled?): IRawForm => {
  return getCheckbox({
    id: `${CAR_SAME_ENERGY_PRODUCED_AS_IN_CCR}${disabled ? '_ro' : ''}`,
    key: CAR_SAME_ENERGY_PRODUCED_AS_IN_CCR,
    className: 'col-12',
    change,
    label: CARApplyPrefix(CAR_SAME_QUANTITY_AS_IN_CCR, CAR_STEP_PREFIX),
    required: false,
    hideExpression: 'model.ccr == true || model.section === \'SECTION_95\' || !model.section ',
  });
};

export const getOverrideOfTheDifference = (disabled = false, tooltip?: string, keyToIdentifyLabel: string = ''): IRawForm[] => {
  return [
    getCheckbox({
      id: `${CAR_OVERRIDE_THE_DIFF_OF_QUANTITY_CHECKBOX_FLD}${disabled ? '_ro' : ''}`,
      key: CAR_OVERRIDE_THE_DIFF_OF_QUANTITY_CHECKBOX_FLD,
      className: 'col-12',
      label: CARApplyPrefix(CAR_OVERRIDE_THE_DIFF_OF_QUANTITY_CHECKBOX_FLD, CAR_STEP_PREFIX),
      hideExpression: 'model.ccr == true',
      required: false,
    }),
    getInput({
      id: `${CAR_OVERRIDE_THE_DIFF_OF_QUANTITY_FLD}_${keyToIdentifyLabel}`,
      key: CAR_OVERRIDE_THE_DIFF_OF_QUANTITY_FLD,
      className: 'col-12',
      label: CARApplyPrefix(CAR_OVERRIDE_THE_DIFF_OF_QUANTITY_FLD, CAR_STEP_PREFIX),
      translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
      hideExpression: `model.ccr == true || !model.${ CAR_OVERRIDE_THE_DIFF_OF_QUANTITY_CHECKBOX_FLD }`,
      customTemplateOptions: {
        type: 'formatted-number',
        hideArrows: true,
      },
      min: 0,
      icon: {
        text: tooltip,
      },
      validators: ['positiveOrZeroNumber'],
    }),
    getTextArea({
      id: `${CAR_OVERRIDE_THE_DIFF_OF_QUANTITY_REASON_FLD}_${keyToIdentifyLabel}`,
      key: CAR_OVERRIDE_THE_DIFF_OF_QUANTITY_REASON_FLD,
      className: 'col-12',
      label: CARApplyPrefix(CAR_OVERRIDE_THE_DIFF_OF_QUANTITY_REASON_FLD, CAR_STEP_PREFIX),
      translatePrefix: CAR_PREFIX + CAR_STEP_PREFIX,
      hideExpression: `model.ccr == true || !model.${ CAR_OVERRIDE_THE_DIFF_OF_QUANTITY_CHECKBOX_FLD }`,
      icon: {
        text: tooltip,
      },
    }),
  ];
};
