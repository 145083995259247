import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppWrapperComponent } from '@core/components/app-wrapper/app-wrapper.component';
import { FaqComponent } from '@core/components/faq/faq.component';
import { ForgotPasswordComponent } from '@core/components/forgot-password/forgot-password.component';
import { ForgotUsernameComponent } from '@core/components/forgot-username/forgot-username.component';
import { LandingPageComponent } from '@core/components/landing-page/landing-page.component';
import { LoginComponent } from '@core/components/login/login.component';
import { AuthenticatedGuard } from '@core/guards/authenticated.guard';
import { FirstTabRedirectGuard } from '@core/guards/first-tab-redirect.guard';
import { UnauthenticatedGuard } from '@core/guards/unauthenticated.guard';
import { cfsMatcher } from '@core/matchers/cfs.matcher';
import { obpsMatcher } from '@core/matchers/obps.matcher';
import { offsetMatcher } from '@core/matchers/offset.matcher';
import { cmMatcher } from '@core/matchers/cm.matcher';
import { LocalizeParser, LocalizeRouterModule, LocalizeRouterSettings, ManualParserLoader } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { ChooserPageComponent } from '@shared/components/chooser-page/chooser-page.component';
import { OffsetAccountTypeComponent } from '@shared/components/offset-account-type/offset-account-type.component';
import { PendingRegistrationsComponent } from '@shared/components/pending-registrations/pending-registrations.component';
import { ProjectBuilderWrapperComponent } from '@shared/components/project-builder/project-builder-wrapper/project-builder-wrapper.component';
import { ProjectTransferListComponent } from '@shared/components/project-builder/project-transfer-list/project-transfer-list.component';
import { ProjectTransferRequestComponent } from '@shared/components/project-builder/project-transfer-request/project-transfer-request.component';
import { SubProjectWrapperComponent } from '@shared/components/project-builder/sub-project-wrapper/sub-project-wrapper.component';
import {
  TransferAggregationRequestComponent
} from '@shared/components/project-builder/transfer-aggregation-request/transfer-aggregation-request.component';
import { OrgRegProgressComponent } from '@shared/components/steps/cfr-registration/org-reg-progress/org-reg-progress.component';
import { OffsetRegProgressComponent } from '@shared/components/steps/offset-reg-progress/offset-reg-progress.component';
import { LOCALES } from './app.const';
import {
  CREATE_REPORT_NOTIFICATION_MODAL_NOTIFICATION,
  CREATE_REPORT_NOTIFICATION_MODAL_REPORT
} from '@module/submission-management/components/create-report-notification-modal/create-report-notification-modal.constants';
import { SubProjectsAdditionComponent } from '@shared/components/project-builder/sub-projects-addition/sub-projects-addition.component';
import { ProjectListWrapperComponent } from '@shared/components/project-builder/project-list-wrapper/project-list-wrapper.component';
import { PublicViewEnabledGuard } from '@core/guards/public-view-enabled.guard';

const AppRoutes: Routes = [
  {
    path: 'welcome',
    component: LandingPageComponent,
    canActivate: [UnauthenticatedGuard],
  },
  {
    path: 'public-registry',
    canActivate: [UnauthenticatedGuard, PublicViewEnabledGuard],
    loadChildren: () => import('./modules/offset-public-registry/offset-public-registry.module').then(m => m.OffsetPublicRegistryModule),
    data:{title:'offsetPublicViewPages'}
  },
  {
    path: 'learn-more',
    loadChildren: () => import('./core/components/about-us/about-us.module').then(m => m.AboutUsModule),
    canActivate: [UnauthenticatedGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [UnauthenticatedGuard],
  },
  {
    path: 'login/:id',
    component: LoginComponent,
    canActivate: [UnauthenticatedGuard],
  },
  {
    path: 'reset-password',
    component: ForgotPasswordComponent,
    canActivate: [UnauthenticatedGuard],
  },
  {
    path: 'forgot-username',
    component: ForgotUsernameComponent,
  },
  {
    path: 'confirm-password/:access',
    component: ForgotPasswordComponent,
    canActivate: [UnauthenticatedGuard],
  },
  {
    path: 'chooser-page',
    component: ChooserPageComponent,
  },
  {
    path: 'chooser-page/:code',
    component: ChooserPageComponent,
  },
  {
    path: 'chooser-page/faq',
    component: FaqComponent,
  },
  {
    path: '',
    component: AppWrapperComponent,
    canActivate: [AuthenticatedGuard],
    children: [
      {
        path: '',
        canActivate: [FirstTabRedirectGuard],
        component: PendingRegistrationsComponent,
      },
      {
        data: { path: 'registration-pending' },
        matcher: obpsMatcher,
        component: PendingRegistrationsComponent,
      },
      {
        data: { path: 'registration-pending' },
        matcher: cfsMatcher,
        component: OrgRegProgressComponent,
      },
      {
        data: { path: 'registration-pending' },
        matcher: offsetMatcher,
        component: OffsetRegProgressComponent,
      },
      {
        data: { path: 'registration-pending' },
        matcher: cmMatcher,
        component: OffsetRegProgressComponent,
      },
      {
        path: 'registration-pending/user',
        canActivate: [AuthenticatedGuard],
        component: OrgRegProgressComponent,
      },
      {
        path: 'account-management',
        loadChildren: () => import('./modules/account-management/account-management.module').then(m => m.AccountManagementModule),
      },
      {
        path: 'activity-logs',
        // loadChildren: () => import('./modules/manage-facilities/manage-facilities.module').then(m => m.ManageFacilitiesModule),
        loadChildren: () => import('./modules/activity-logs/activity-logs.module').then(m => m.ActivityLogsModule),
      },
      {
        path: 'reports',
        loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule),
      },
      {
        path: 'cross-program/reports',
        loadChildren: () => import('./modules/cross-program-reports/cross-program-reports.module').then(m => m.CrossProgramReportsModule),
      },
      {
        path: 'admin',
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
      },
      {
        path: 'user',
        loadChildren: () => import('./modules/users-management/users-management.module').then(m => m.UsersManagementModule),
      },
      {
        path: 'program-management',
        loadChildren: () => import('./modules/program-management/program-management.module').then(m => m.ProgramManagementModule),
      },
      {
        path: 'program-cancellation/:id',
        loadChildren: () => import('./modules/program-cancellation/program-cancellation.module').then(m => m.ProgramCancellationModule),
      },
      {
        path: 'tasks',
        loadChildren: () => import('./modules/tasks/tasks.module').then(m => m.TasksModule),
      },
      {
        path: 'unit-management',
        loadChildren: () => import('./modules/unit-management/unit-management.module').then(m => m.UnitManagementModule),
      },
      {
        path: 'environment-integrity/account',
        loadChildren: () => import('./modules/environmental-integrity-account/environmental-integrity-account.module').then(m => m.EnvironmentalIntegrityAccountModule),
      },
      {
        path: 'obligation-management',
        loadChildren: () => import('./modules/obligation-management/obligation-management.module').then(m => m.ObligationManagementModule),
      },
      {
        path: 'credit-bulletin',
        loadChildren: () => import('./modules/bulletin-board/bulletin-board.module').then(m => m.BulletinBoardModule),
      },
      {
        path: 'task',
        loadChildren: () => import('./modules/tasks/tasks.module').then(m => m.TasksModule),
      },
      {
        path: 'cfr-registration',
        loadChildren: () => import('./modules/cfr-registration/cfr-registration.module').then(m => m.CfrRegistrationModule),
      },
      {
        path: 'manage-facilities',
        loadChildren: () => import('./modules/manage-facilities/manage-facilities.module').then(m => m.ManageFacilitiesModule),
      },
      {
        path: 'submission-management',
        loadChildren: () => import('./modules/submission-management/submission-management.module').then(m => m.SubmissionManagementModule),
      },
      {
        path: 'verification',
        loadChildren: () => import('./modules/verification/verification.module').then(m => m.VerificationModule),
      },
      {
        path: 'agreement-credits',
        loadChildren: () => import('./modules/agreement-credits/agreement-credits.module').then(m => m.AgreementCreditsModule),
      },
      {
        path: 'carbon-intensity',
        loadChildren: () => import('./modules/carbon-intensity/carbon-intensity.module').then(m => m.CarbonIntensityModule),
      },
      {
        path: 'offset-registration/:accountId',
        component: OffsetAccountTypeComponent,
      },
      {
        path: 'offset-registration/:accountId/:id',
        component: OffsetAccountTypeComponent,
      },
      {
        path: 'project-builder/sub/:masterProjectId',
        component: SubProjectsAdditionComponent,
      },
      {
        path: 'project-builder/sub/:masterProjectId/:id',
        component: SubProjectWrapperComponent,
      },
      {
        path: 'project-builder/:type/:accountId',
        component: ProjectBuilderWrapperComponent,
      },
      {
        path: 'project-builder/:type/:accountId/:id',
        component: ProjectBuilderWrapperComponent,
      },
      {
        path: 'corrected-report/:reportType/:accountId/:projectId/:revisedFor/:noeSubmissionId',
        data: { typeId: CREATE_REPORT_NOTIFICATION_MODAL_REPORT },
        loadChildren: () => import('./modules/project-report/project-report.module').then(m => m.ProjectReportModule),
      },
      {
        path: 'corrected-report/:reportType/:accountId/:projectId/:revisedFor',
        data: { typeId: CREATE_REPORT_NOTIFICATION_MODAL_REPORT },
        loadChildren: () => import('./modules/project-report/project-report.module').then(m => m.ProjectReportModule),
      },
      {
        path: 'project-report/:reportType/:accountId/:projectId',
        data: { typeId: CREATE_REPORT_NOTIFICATION_MODAL_REPORT },
        loadChildren: () => import('./modules/project-report/project-report.module').then(m => m.ProjectReportModule),
      },
      {
        path: 'project-report/:reportType/:accountId/:projectId/:entityId',
        data: { typeId: CREATE_REPORT_NOTIFICATION_MODAL_REPORT },
        loadChildren: () => import('./modules/project-report/project-report.module').then(m => m.ProjectReportModule),
      },
      {
        path: 'project-report/:reportType/:accountId/:projectId/:entityId/:newReport',
        data: { typeId: CREATE_REPORT_NOTIFICATION_MODAL_REPORT },
        loadChildren: () => import('./modules/project-report/project-report.module').then(m => m.ProjectReportModule),
      },
      {
        path: 'project-notification/:notificationType/:accountId/:projectId',
        data: { typeId: CREATE_REPORT_NOTIFICATION_MODAL_NOTIFICATION },
        loadChildren: () => import('./modules/project-report/project-report.module').then(m => m.ProjectReportModule),
      },
      {
        path: 'project-notification/:notificationType/:accountId/:projectId/:entityId',
        data: { typeId: CREATE_REPORT_NOTIFICATION_MODAL_NOTIFICATION },
        loadChildren: () => import('./modules/project-report/project-report.module').then(m => m.ProjectReportModule),
      },
      {
        path: 'project/offset/:accountId/:id',
        loadChildren: () => import('./shared/components/project/project.module').then(m => m.ProjectModule),
      },
      {
        path: 'credit-creation-reports',
        loadChildren: () => import('./modules/credit-creation-reports/credit-creation-reports.module').then(m => m.CreditCreationReportsModule),
      },
      {
        path: 'credit-adjustment-reports',
        loadChildren: () => import('./modules/credit-adjustment-report/credit-adjustment-report.module').then(m => m.CreditAdjustmentReportModule),
      },
      {
        path: 'ci-pathway-reports',
        loadChildren: () => import('./modules/ci-pathway-report/ci-pathway-report.module').then(m=> m.CiPathwayReportModule),
      },
      {
        path: 'notice-of-error',
        loadChildren: () => import('./modules/notice-of-error/notice-of-error.module').then(m => m.NoticeOfErrorModule),
      },
      {
        path: 'notice-of-suspension',
        loadChildren: () => import('./modules/notice-of-error/notice-of-error.module').then(m => m.NoticeOfErrorModule),
      },
      {
        path: 'project-list/:id',
        component: ProjectListWrapperComponent,
      },
      {
        path: 'project-transfer-request/:id/:type',
        component: ProjectTransferRequestComponent,
      },
      {
        path: 'aggregation-transfer-request/:id/:projectPid/:srcAccountId',
        component: TransferAggregationRequestComponent,
      },
      {
        path: 'project-transfer-request-list/:id',
        component: ProjectTransferListComponent,
      }, {
        path: 'review-project-transfer-request/:id',
        component: ProjectTransferRequestComponent,
      },
      {
        path: 'monitoring-report',
        loadChildren: () => import('./modules/project-monitoring-report/project-monitoring-report.module').then(m => m.ProjectMonitoringReportModule),
      },
      {
        path: 'emissions-reduction',
        loadChildren: () => import('./modules/emissions-reduction-projects/emissions-reduction-projects.module').then(m => m.EmissionsReductionProjectsModule),
      },
      {
        path: 'compliance-report',
        loadChildren: () => import('./modules/compliance-report/compliance-report.module').then(m => m.ComplianceReportModule),
      },
      {
        path: 'credit-clearance/mechanism',
        loadChildren: () => import('./modules/credit-clearance-mechanism/credit-clearance-mechanism.module').then(m => m.CreditClearanceMechanismModule),
      },
      {
        path: 'credit-balance',
        loadChildren: () => import('./modules/credit-balance/credit-balance.module').then(m => m.CreditBalanceModule),
      },
      {
        path: 'complimentary-compliance-report',
        loadChildren: () => import('./modules/complimentary-compliance-report/complimentary-compliance-report.module').then(m => m.ComplimentaryComplianceReportModule),
      },
      {
        path: 'credit-revenue',
        loadChildren: () => import('./modules/credit-revenue-report/credit-revenue-report.module').then(m => m.CreditRevenueReportModule),
      },
      {
        path: 'material-balance',
        loadChildren: () => import('./modules/material-balance-report/material-balance-report.module').then(m => m.MaterialBalanceReportModule),
      },
    ],
  },
  {
    path: 'register',
    loadChildren: () => import('./modules/registration/registration.module').then(m => m.RegistrationModule),
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(AppRoutes, { useHash: false, scrollPositionRestoration: 'top', onSameUrlNavigation: 'reload' }),
    LocalizeRouterModule.forRoot(AppRoutes, {
      parser: {
        provide: LocalizeParser,
        useFactory: (translate, location, settings) =>
          new ManualParserLoader(translate, location, settings, LOCALES, 'ROUTES.routes.'),
        deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient],
      },
    }),
  ],
  exports: [
    RouterModule,
  ],
})
export class AppRoutingModule { }
