<p-dialog
  #dialog
  [className]="'eccc'"
  [(visible)]="visible"
  [modal]="true"
  [responsive]="true"
  [style]="{width: '1500px'}"
  [minY]="100"
  [maximizable]="false"
  [autoZIndex]="false"
  [closable]="false"
  (onShow)="scrollToTop()"
  [header] = "headerText | translate">

  <ng-container *ngIf="service.currentStep.code !== 'STEP-SUB_SEC_9_3'; else SECTION_9_3">
    <h3 *ngIf="data.viewDetailsData.length" class="m-0 mt-4 pb-2"> {{translationPrefix + '.viewDetailsHeading' | translate}} </h3>
    <h3 *ngIf="!data.viewDetailsData.length" class="m-0 mt-4 pb-2"> {{translationPrefix + '.noDataFoundCCR' | translate}} </h3>

    <form [formGroup]="form">
      <formly-form [form]="form" [model]="model"  [fields]="fields"></formly-form>
    </form>
  </ng-container>

  <ng-template #SECTION_9_3>
    <h3 *ngIf="data.viewDetailsData.length" class="m-0 mt-4 pb-2"> {{translationPrefix + '.viewDetailsHeading_Sec_9_3' | translate}} </h3>
    <h3 *ngIf="!data.viewDetailsData.length" class="m-0 mt-4 pb-2"> {{translationPrefix + '.noDataFoundCCR' | translate}} </h3>
  </ng-template>


  <div class="table-area table1" *ngIf="data.viewDetailsData.length" >
    <app-table-view
      [data]="tableData"
      [config]="tableConfig"
    >
    </app-table-view>
  </div>

  <div class="document-container" style="margin-top: 2rem;" *ngIf="documentUploadConfig && data.viewDetailsData.length">
    <app-documents
      [documentUploadConfig]="documentUploadConfig"
      [showPlaceholders]="true"
      [relative]="true"
      (documentResult)="updateUploadedDocuments($event)"
      [disabled]="service.disabled">
    </app-documents>
  </div>

  <div class="d-flex justify-content-end mt-2" style="padding-top: 1rem;">
    <button type="button" disableOnUpload class="btn btn-secondary mr-2" (click)="close()">{{'CREDIT_CREATION_REPORT.reportStep.close' | translate}}</button>
    <button type="button" *ngIf="!service.disabled && data.viewDetailsData.length"
            disableOnUpload
            class="btn btn-primary"
            (click)="save()">
      {{'CREDIT_CREATION_REPORT.reportStep.save' | translate}}
    </button>
  </div>
</p-dialog>

<ng-template #CheckboxColTemplate let-data>
  <input
  #inputEl
  [(ngModel)]="data.overrideComplianceCredits"
  class="checkbox-align"
  type="checkbox"
  [disabled]="service.disabled"
  [id]="data.id +'overrideComplianceCredits'"
  [attr.aria-labelledby]="data.id +'overrideComplianceCredits'"
/>
</ng-template>

<ng-template #inputColTemplate let-data>
<input
*ngIf="data.overrideComplianceCredits"
#inputEl
[allowNegativeNumbers]="false"
[maxlength]="'19'"
[max]="data.quantity"
[mask]="'separator.5'"
[thousandSeparator]="commaSeparator"
[(ngModel)]="data.complianceCreditsCreated"
[disabled]="service.disabled"
class="form-control"
type="text"
[id]="data.id + 'complianceCreditsCreated'"
[attr.aria-labelledby]="data.id + 'complianceCreditsCreated'"
/>

<div class="invalid-feedback d-block"
   *ngIf="!data.overrideComplianceCredits">
<span class="table-action-text">N/A</span>
</div>
<div
class="invalid-feedback d-block"
*ngIf="data.complianceCreditsCreated && !(data.complianceCreditsCreated >= 0)">
<span>{{ 'COMMON.messageSection.valueCannotBeLessThanZero' | translate }}</span>
</div>
</ng-template>

<ng-template #allocateVolColTemplate let-data>
<input

#inputEl
[allowNegativeNumbers]="false"
[maxlength]="'19'"
[max]="data.quantity"
[mask]='separatorMask'
[thousandSeparator]="commaSeparator"
[(ngModel)]="data.complianceCreditQuantity"
[disabled]="service.disabled"
[class.is-invalid]="!service.disabled && !data.disabled && data._quantityAvailableRaw < 0"
class="form-control"
(change)="onVolumneAllocation(data)"
type="text"
[id]="data.id + 'complianceCreditQuantity'"
[attr.aria-labelledby]="data.id  + 'complianceCreditQuantity'"
/>

<div
class="invalid-feedback d-block"
*ngIf="data._quantityAvailableRaw < 0">
<span>{{ 'COMMON.messageSection.compRepQuantityExceeded' | translate }}</span>
</div>

<div
class="invalid-feedback d-block"
*ngIf="data.complianceCreditQuantity && !(data.complianceCreditQuantity >= 0)">
<span>{{ 'COMMON.messageSection.valueCannotBeLessThanZero' | translate }}</span>
</div>

</ng-template>


<ng-template #complianceQuantityTemplate let-data>
  <input

  #inputEl
  [allowNegativeNumbers]="false"
  [maxlength]="'19'"
  [max]="data.quantity"
  [mask]='separatorMask'
  [thousandSeparator]="commaSeparator"
  [(ngModel)]="data.complianceCreditQuantity"
  [disabled]="service.disabled"
  [class.is-invalid]="!service.disabled && (data.complianceCreditQuantity < 0  || (!data.complianceCreditQuantity && (data.approvedCliValue || data.gaseousComplianceCredits || data.liquidComplianceCredits || data.energyDensity || data.alphanumericId)))"
  class="form-control"
  type="text"
  [id]="data.id + 'complianceCreditQuantity'"
  [attr.aria-labelledby]="data.id + 'complianceCreditQuantity'"
  />

  <div
  class="invalid-feedback d-block"
  *ngIf="data.complianceCreditQuantity && !(data.complianceCreditQuantity >= 0)">
  <span>{{ 'COMMON.messageSection.valueCannotBeLessThanZero' | translate }}</span>
  </div>

  <div
  class="invalid-feedback d-block"
  *ngIf="(!data.complianceCreditQuantity && (data.approvedCliValue || data.gaseousComplianceCredits || data.liquidComplianceCredits || data.energyDensity || data.alphanumericId))">
  <span>{{ 'COMMON.messageSection.fieldRequiredException' | translate }}</span>
  </div>

</ng-template>

<ng-template #energyDensityTemplate let-data>
  <input

  #inputEl
  [allowNegativeNumbers]="false"
  [maxlength]="'19'"
  [max]="data.quantity"
  [mask]='separatorMask'
  [thousandSeparator]="commaSeparator"
  [(ngModel)]="data.energyDensity"
  [disabled]="service.disabled"
  [class.is-invalid]="!service.disabled && data.energyDensity < 0"
  class="form-control"
  type="text"
  [id]="data.id +'energyDensity'"
  [attr.aria-labelledby]="data.id +'energyDensity'"
  />

  <div
  class="invalid-feedback d-block"
  *ngIf="data.energyDensity && !(data.energyDensity >= 0)">
  <span>{{ 'COMMON.messageSection.valueCannotBeLessThanZero' | translate }}</span>
  </div>

</ng-template>

<ng-template #liquidComplianceCreditTemplate let-data>
  <input

  #inputEl
  [allowNegativeNumbers]="false"
  [maxlength]="'19'"
  [max]="data.quantity"
  [mask]='separatorMask'
  [thousandSeparator]="commaSeparator"
  [(ngModel)]="data.liquidComplianceCredits"
  [disabled]="service.disabled"
  [class.is-invalid]="!service.disabled && data.liquidComplianceCredits < 0"
  class="form-control"
  (change)="calculateTotalCredits(data)"
  type="text"
  [id]="data.id +'liquidComplianceCredits'"
  [attr.aria-labelledby]="data.id +'liquidComplianceCredits'"
  />

  <div
  class="invalid-feedback d-block"
  *ngIf="data.liquidComplianceCredits && !(data.liquidComplianceCredits >= 0)">
  <span>{{ 'COMMON.messageSection.valueCannotBeLessThanZero' | translate }}</span>
  </div>

</ng-template>
<ng-template #gaseousCompliacneCreditTemplate let-data>
  <input

  #inputEl
  [allowNegativeNumbers]="false"
  [maxlength]="'19'"
  [max]="data.quantity"
  [mask]='separatorMask'
  [thousandSeparator]="commaSeparator"
  [(ngModel)]="data.gaseousComplianceCredits"
  [disabled]="service.disabled"
  [class.is-invalid]="!service.disabled && data.gaseousComplianceCredits < 0"
  class="form-control"
  (change)="calculateTotalCredits(data)"
  type="text"
  [id]="data.id +'gaseousComplianceCredits'"
  [attr.aria-labelledby]="data.id +'gaseousComplianceCredits'"
  />

  <div
  class="invalid-feedback d-block"
  *ngIf="data.gaseousComplianceCredits && !(data.gaseousComplianceCredits >= 0)">
  <span>{{ 'COMMON.messageSection.valueCannotBeLessThanZero' | translate }}</span>
  </div>

</ng-template>

<ng-template #alphanumericIdTemplate let-data>
  <input

  #inputEl
  [(ngModel)]="data.alphanumericId"
  [disabled]="service.disabled"
  [class.is-invalid]="!service.disabled && (!data.alphanumericId && (data.approvedCliValue || data.gaseousComplianceCredits || data.liquidComplianceCredits || data.energyDensity || data.complianceCreditQuantity))"
  class="form-control"
  (change)="onAlphanumericIdChange(data)"
  type="text"
  [id]="data.id +'alphanumericId'"
  [attr.aria-labelledby]="data.id +'alphanumericId'"
  />

  <div
  class="invalid-feedback d-block"
  *ngIf="(!data.alphanumericId && (data.approvedCliValue || data.gaseousComplianceCredits || data.liquidComplianceCredits || data.energyDensity || data.complianceCreditQuantity))">
  <span>{{ 'COMMON.messageSection.fieldRequiredException' | translate }}</span>
  </div>

</ng-template>

<ng-template #approvedCliValueTemplate let-data>
  <input

  #inputEl
  [allowNegativeNumbers]="false"
  [maxlength]="'19'"
  [max]="data.quantity"
  [mask]='separatorMask'
  [thousandSeparator]="commaSeparator"
  [(ngModel)]="data.approvedCliValue"
  [disabled]="service.disabled"
  [class.is-invalid]="!service.disabled && data.approvedCliValue < 0"
  class="form-control"
  (change)="onapprovedCliValueChange(data)"
  type="text"
  [id]="data.id +'approvedCliValue'"
  [attr.aria-labelledby]="data.id +'approvedCliValue'"
  />

  <div
  class="invalid-feedback d-block"
  *ngIf="data.approvedCliValue < 0">
  <span>{{ 'COMMON.messageSection.valueCannotBeLessThanZero' | translate }}</span>
  </div>

</ng-template>
