import { IRawFormBase } from '@core/models/raw-form.types';
import { SOURCE_DATA_TYPE } from '@shared/components/stepper/stepper.const';
import { EDIT_PROJECT_PUBLIC_VISIBILITY, VIEW_PROJECT_PUBLIC_VISIBILITY } from '@core/models/permission.const';

export const SUB_PROJECT_FORM: IRawFormBase[] = [
  {
    fieldGroupClassName: 'pl-4 row',
    fieldGroup: [
      {
        type: 'input',
        key: 'name',
        className: 'col-6',
        label: 'PROJECTS_MODULE.ProjectForms.subProjectNameEn',
        disabled: false,
        required: true,
        id: 'subProjecName',
        ignoreLocalFormDisabled: true
      },
      {
        type: 'input',
        key: 'name2',
        className: 'col-6',
        label: 'PROJECTS_MODULE.ProjectForms.subProjectNameFr',
        disabled: false,
        required: false,
        id: 'subProjectName2',
        ignoreLocalFormDisabled: true,
        expressionProperties: {
          'templateOptions.disabled': '(model.disabled && !model.editPublicVisibility)',
        },
      },
    ],
  },
  {
    fieldGroupClassName: 'pl-4 row',
    fieldGroup: [
      {
        type: 'app-formly-datepicker',
        key: 'activity.subProjectRequestDate',
        className: 'col-6',
        label: 'SPROJECTS_MODULE.ProjectForms.subProjectRequestedDate',
        disabled: true,
        required: true,
        id: 'subProjectRequestDate',
        hideExpression: true,
      },
      {
        type: 'app-formly-datepicker',
        key: 'activity.registrationDate',
        className: 'col-6',
        label: 'SPROJECTS_MODULE.ProjectForms.subProjectRegistrationDate',
        disabled: true,
        required: true,
        id: 'subProjectRegistrationDate',
        hideExpression: true,
      },
    ],
  },
  {
    groupFields: true,
    label: 'PROJECTS_MODULE.ProjectForms.location',
    class: 'pl-2 mt-3',
    wrappers: ['simple-border'],
    // fieldGroupClassName: 'pl-4 row',
    fieldGroup: [
      {
        type: 'dict-select',
        key: 'activity.provinceId',
        source: SOURCE_DATA_TYPE.PROVINCE_BY_COUNTRY,
        translatePrefix: 'COMMON.jurisdictionsList',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.province',
        disabled: true,
        required: true,
        id: `sub_province`,
        ignoreDropdownAppend: true,
        hideExpression: true
      },
    ],
  },
  {
    fieldGroupClassName: 'pl-4 row',
    fieldGroup: [
      {
        key: 'activity.location.type',
        type: 'radio',
        className: 'col-6 d-none',
        label: 'PROJECTS_MODULE.ProjectForms.subProjectLocationBoundaries',
        dummyInputForLabel: true,
        defaultValue: 'DECIMAL',
        required: true,
        options: [
          // { value: 'PROJECTS_MODULE.ProjectForms.degreeLatLong', key: 'DEGREE' },
          { value: 'PROJECTS_MODULE.ProjectForms.decimalLatLong', key: 'DECIMAL' },
          {
            value: 'PROJECTS_MODULE.ProjectForms.fileLatLong',
            key: 'FILE',
            tooltip: 'PROJECTS_MODULE.ProjectForms.kmlFileTooltip',
            tooltipClass: 'tooltip-min-width',
          },
        ],
      },
    ],
  },
  {
    fieldGroupClassName: 'pl-4 row',
    fieldGroup: [
      {
        type: 'template',
        parse: true,
        translate: true,
        label: 'PROJECTS_MODULE.ProjectForms.decimalLatLong',
        className: 'col-12',
      },
      {
        type: 'input',
        key: 'activity.location.latitudeDecimal',
        className: 'col-6',
        label: 'PROJECTS_MODULE.ProjectForms.latitude',
        formDisabled: false,
        changeExpr: 'this.inputChange($event, field)',
        required: true,
        id: 'sub_latitude',
        hideExpression: "model.activity.location.type !== 'DECIMAL'",
        disabledExpression: "model.activity.location.type !== 'DECIMAL' || model.disabled",
        ignoreLocalFormDisabled: true,
        // customTemplateOptions: {
        //   type: 'number'
        // }
        max: 90,
        min: -90,
      },
      {
        type: 'input',
        key: 'activity.location.longitudeDecimal',
        className: 'col-6',
        label: 'PROJECTS_MODULE.ProjectForms.longitude',
        changeExpr: 'this.inputChange($event, field)',
        formDisabled: false,
        required: true,
        id: 'sub_longitude',
        hideExpression: "model.activity.location.type !== 'DECIMAL'",
        disabledExpression: "model.activity.location.type !== 'DECIMAL' || model.disabled",
        ignoreLocalFormDisabled: true,
        // customTemplateOptions: {
        //   type: 'number'
        // }
        max: 180,
        min: -180,
      },
      {
        type: 'button',
        label: 'PROJECTS_MODULE.ProjectForms.browse',
        className: 'mx-5',
        hideExpression: "model.activity.location.type !== 'FILE'",
        disabledExpression: "model.activity.location.type !== 'FILE'",
        subProject: true,
      },
      {
        type: 'template',
        label: '',
        className: 'col-6',
        parse: true,
        id: 'downloadLinkSubProject',
        hideExpression: "model.activity.location.type !== 'FILE'",
        disabledExpression: "model.activity.location.type !== 'FILE'",
      },
      {
        type: 'input',
        key: 'activity.location.file',
        className: 'col-1 file-input',
        // required:true,
        requiredExpression: '!model.activity.location.kmlFileExists',
        id: 'mapFileSubProject',
        hideExpression: "model.activity.location.type !== 'FILE'",
        disabledExpression: "model.activity.location.type !== 'FILE'",
        changeExpr: 'this.fileUploadEvent($event, field)',
        customTemplateOptions: {
          type: 'file',
          tabIndex: -1,
        },
      },
    ],
  },
  {
    fieldGroupClassName: 'pl-4 row',
    fieldGroup: [
      {
        type: 'template',
        parse: true,
        translate: true,
        label: 'PROJECTS_MODULE.ProjectForms.latitudeRequired',
        className: 'col-12',
        hideExpression: "model.activity.location.type !== 'DEGREE'",
        disabledExpression: "model.activity.location.type !== 'DEGREE' || model.disabled",
      },
      {
        type: 'input',
        key: 'activity.location.latitudeDegree',
        className: 'col-4 mt-n3',
        formDisabled: false,
        required: true,
        id: 'sub_latitudeDegree',
        hideExpression: "model.activity.location.type !== 'DEGREE'",
        disabledExpression: "model.activity.location.type !== 'DEGREE' || model.disabled",
        ignoreLocalFormDisabled: true,
        placeholder: 'PROJECTS_MODULE.ProjectForms.degree',
        customTemplateOptions: {
          type: 'number',
        },
        max: 90,
        min: -90,
      },
      {
        type: 'input',
        key: 'activity.location.latitudeMinute',
        className: 'col-4 mt-n3',
        formDisabled: false,
        required: true,
        id: 'sub_latitudeMin',
        hideExpression: "model.activity.location.type!== 'DEGREE'",
        disabledExpression: "model.activity.location.type !== 'DEGREE' || model.disabled",
        ignoreLocalFormDisabled: true,
        placeholder: 'PROJECTS_MODULE.ProjectForms.minutes',
        customTemplateOptions: {
          type: 'number',
        },
        max: 60,
        min: 0,
      },
      {
        type: 'input',
        key: 'activity.location.latitudeSecond',
        className: 'col-4 mt-n3',
        formDisabled: false,
        required: true,
        id: 'sub_latitudeSec',
        hideExpression: "model.activity.location.type !== 'DEGREE'",
        disabledExpression: "model.activity.location.type !== 'DEGREE' || model.disabled",
        ignoreLocalFormDisabled: true,
        placeholder: 'PROJECTS_MODULE.ProjectForms.seconds',
        customTemplateOptions: {
          type: 'number',
        },
        max: 60,
        min: 0,
      },
      {
        type: 'template',
        parse: true,
        translate: true,
        label: 'PROJECTS_MODULE.ProjectForms.longitudeRequired',
        className: 'col-12',
        hideExpression: "model.activity.location.type !== 'DEGREE'",
        disabledExpression: "model.activity.location.type !== 'DEGREE' || model.disabled",
        ignoreLocalFormDisabled: true,
      },
      {
        type: 'input',
        key: 'activity.location.longitudeDegree',
        className: 'col-4 mt-n3',
        formDisabled: false,
        required: true,
        id: 'sub_longitudeDegree',
        hideExpression: "model.activity.location.type !== 'DEGREE'",
        disabledExpression: "model.activity.location.type !== 'DEGREE' || model.disabled",
        ignoreLocalFormDisabled: true,
        placeholder: 'PROJECTS_MODULE.ProjectForms.degree',
        customTemplateOptions: {
          type: 'number',
        },
        max: 180,
        min: -180,
      },
      {
        type: 'input',
        key: 'activity.location.longitudeMinute',
        className: 'col-4 mt-n3',
        formDisabled: false,
        required: true,
        id: 'sub_longitudeMin',
        hideExpression: "model.activity.location.type !== 'DEGREE'",
        disabledExpression: "model.activity.location.type !== 'DEGREE' || model.disabled",
        ignoreLocalFormDisabled: true,
        placeholder: 'PROJECTS_MODULE.ProjectForms.minutes',
        customTemplateOptions: {
          type: 'number',
        },
        max: 60,
        min: 0,
      },
      {
        type: 'input',
        key: 'activity.location.longitudeSecond',
        className: 'col-4 mt-n3',
        formDisabled: false,
        required: true,
        id: 'sub_longitudeSec',
        hideExpression: "model.activity.location.type !== 'DEGREE'",
        disabledExpression: "model.activity.location.type !== 'DEGREE' || model.disabled",
        ignoreLocalFormDisabled: true,
        placeholder: 'PROJECTS_MODULE.ProjectForms.seconds',
        customTemplateOptions: {
          type: 'number',
        },
        max: 60,
        min: 0,
      },
    ],
  },
  {
    groupFields: false,
    wrappers: ['simple-border'],
    fieldGroupClassName: 'pl-4 row',
    fieldGroup: [
      {
        type: 'textarea',
        className: 'pl-3 col-12',
        key: 'municipality',
        label: 'PROJECTS_MODULE.ProjectForms.municipality',
        disabled: false,
        required: true,
        id: 'municipality',
      },
    ],
  },
  {
    groupFields: true,
    label: 'PROJECTS_MODULE.ProjectForms.subProjectDetails',
    class: 'pl-2 mt-3',
    wrappers: ['simple-border'],
    fieldGroup: [
      {
        className: 'col-12',
        type: 'textarea',
        key: 'description',
        label: 'PROJECTS_MODULE.ProjectForms.subProjectDescriptionen',
        disabled: false,
        required: true,
        id: 'subProjectDescription',
        // expressionProperties: {
        //   'templateOptions.disabled': '(model.disabled && !model.adminPermisson)',
        // },
      },
      {
        className: 'col-12',
        type: 'textarea',
        key: 'description2',
        label: 'PROJECTS_MODULE.ProjectForms.projectDescriptionfr',
        disabled: false,
        required: false,
        id: 'subProjectDescription2',
        expressionProperties: {
          'templateOptions.disabled': '(model.disabled && !model.editPublicVisibility)',
        },
      },
      {
        key: 'activity.activityStartDate',
        type: 'app-formly-datepicker',
        className: 'col-sm-12 col-md-6',
        label: 'PROJECTS_MODULE.ProjectForms.subProjectStartDate',
        required: true,
        id: 'subProjectStartDate',
      },
      {
        // type: "dict-select",
        type: 'multi-checkbox',
        key: 'activity.greenhouseGasIds',
        bindLabel: 'code',
        translatePrefix: 'COMMON.lookupFormData',
        source: SOURCE_DATA_TYPE.GREEN_HOUSE_GAS,
        label: 'PROJECTS_MODULE.ProjectForms.greenHouseGas',
        placeholder: 'PROJECTS_MODULE.ProjectForms.selectItem',
        dummyInputForLabel: true,
        disabled: false,
        required: true,
        multiple: true,
        id: `sub_greenhouseGas`,
      },
      {
        key: 'activity.creditingPeriodStartDate',
        type: 'app-formly-datepicker',
        className: 'col-sm-12 col-md-6',
        label: 'PROJECTS_MODULE.ProjectForms.creditingStartDate',
        required: true,
        // disabled: true, // TODO: toggle disable based on role if admin then false.
        id: 'sub_creditingPeriodStartDate',
        hideExpression: '!model.activity._creditingPeriodStartDateVisible || model.editParticipant',
        expressionProperties: {
          'templateOptions.disabled':
            '(model.disabled && !model.adminPermisson) || (!model.activity._creditingPeriodStartDateVisible || model.editParticipant)',
        },
      },
      {
        key: 'activity.creditingPeriodEndDate',
        type: 'app-formly-datepicker',
        className: 'col-sm-12 col-md-6',
        label: 'PROJECTS_MODULE.ProjectForms.creditingEndDate',
        required: true,
        // disabled: true, // TODO: toggle disable based on role if admin then false.
        id: 'sub_creditingPeriodEndDate',
        hideExpression: '!model.activity._creditingPeriodEndDateVisible || model.editParticipant',
        expressionProperties: {
          'templateOptions.disabled':
            '(model.disabled && !model.adminPermisson) || (!model.activity._creditingPeriodEndDateVisible || model.editParticipant)',
        },
      },
      {
        key: 'activity.projectPreviouslyRegistered',
        type: 'checkbox',
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.existingSubProject',
      },
      {
        type: 'input',
        key: 'activity.previousProgramName',
        className: 'col-6',
        formDisabled: false,
        required: true,
        id: 'previousOffsetProgram',
        label: 'PROJECTS_MODULE.ProjectForms.previousOffsetProgram',
        hideExpression: 'model.activity && !model.activity.projectPreviouslyRegistered',
        disabledExpression: 'model.disabled || model.activity && !model.activity.projectPreviouslyRegistered',
        ignoreLocalFormDisabled: true,
      },
    ],
  },
  {
    groupFields: true,
    label: 'PROJECTS_MODULE.ProjectForms.emissionRemovalDetails',
    class: 'pl-2 mt-3',
    wrappers: ['simple-border'],
    fieldGroup: [
      {
        type: 'blurb',
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.ghgFieldsGuidanceText',
      },
      // {
      //   type: 'input',
      //   key: 'activity.projectScenarioGhg',
      //   className: 'col-6',
      //   label: 'PROJECTS_MODULE.ProjectForms.subProjectProjectScenario',
      //   disabled: false,
      //   required: true,
      //   customTemplateOptions: {
      //     type: 'formatted-number',
      //     allowNegativeNumbers: false,
      //   },
      //   changeExpr: 'this.inputChange($event, field)',
      // },
      // {
      //   type: 'input',
      //   key: 'activity.baselineScenarioGhg',
      //   className: 'col-6',
      //   label: 'PROJECTS_MODULE.ProjectForms.subProjectBaseline',
      //   disabled: false,
      //   required: true,
      //   customTemplateOptions: {
      //     type: 'formatted-number',
      //     allowNegativeNumbers: false,
      //   },
      //   changeExpr: 'this.inputChange($event, field)',
      // },
      {
        type: 'input',
        key: 'activity.estimatedScenarioGhg',
        className: 'col-6',
        label: 'PROJECTS_MODULE.ProjectForms.subProjecteEtimatedReductions',
        formDisabled: false,
        required: true,
        customTemplateOptions: {
          type: 'formatted-number',
        },
        changeExpr: 'this.inputChange($event, field)',
      },
    ],
  },
  {
    groupFields: true,
    label: 'PROJECTS_MODULE.ProjectForms.additionalSubProjectDetails',
    class: 'pl-2 mt-3',
    wrappers: ['simple-border'],
    fieldGroup: [
      {
        type: 'dict-select',
        key: 'activity.directFinancialIncentivesReceived',
        className: 'col-6',
        dataProvided: true,
        items: [
          { id: true, name: 'yes' },
          { id: false, name: 'no' },
        ],
        label: 'PROJECTS_MODULE.ProjectForms.directIncentives',
        placeholder: 'selectItem',
        translatePrefix: 'PROJECTS_MODULE.ProjectForms',
        disabled: false,
        required: true,
        id: `directFinancialIncentivesReceived`,
        ignoreDropdownAppend: true,
      },
      {
        type: 'input',
        key: 'activity.programName',
        className: 'col-6',
        label: 'PROJECTS_MODULE.ProjectForms.programName',
        required: true,
        hideExpression: '!model.activity.directFinancialIncentivesReceived',
        disabledExpression: '!model.activity.directFinancialIncentivesReceived',
      },
      {
        type: 'input',
        key: 'activity.percentageCreditForegone',
        className: 'col-6',
        label: 'PROJECTS_MODULE.ProjectForms.percentageCreditForegone',
        required: false,
        hideExpression: '!model.activity.directFinancialIncentivesReceived',
        disabledExpression: '!model.activity.directFinancialIncentivesReceived',
        customTemplateOptions: {
          hideArrows: true,
          type: 'number'
        },
        max: 100,
        min: 0,
      },
      {
        type: 'dict-select',
        key: 'activity.conservationCreditReceived',
        dataProvided: true,
        items: [
          { id: true, name: 'yes' },
          { id: false, name: 'no' },
        ],
        label: 'PROJECTS_MODULE.ProjectForms.conservationCredits',
        placeholder: 'selectItem',
        translatePrefix: 'PROJECTS_MODULE.ProjectForms',
        disabled: false,
        required: true,
        id: `conservationCreditReceived`,
        ignoreDropdownAppend: true,
      },
      {
        type: 'dict-select',
        className: 'col-6',
        key: 'activity.isProponentOwner',
        dataProvided: true,
        items: [
          { id: true, name: 'yes' },
          { id: false, name: 'no' },
        ],
        label: 'PROJECTS_MODULE.ProjectForms.proponentIsOwner',
        placeholder: 'selectItem',
        disabled: false,
        translatePrefix: 'PROJECTS_MODULE.ProjectForms',
        required: false,
        id: `proponentIsOwner`,
        appendTo: 'self',
      },
      {
        type: 'dict-select',
        className: 'col-6',
        key: 'activity.isLandPrivate',
        dataProvided: true,
        items: [
          { id: true, name: 'yes' },
          { id: false, name: 'no' },
        ],
        label: 'PROJECTS_MODULE.ProjectForms.landIsPrivate',
        placeholder: 'selectItem',
        translatePrefix: 'PROJECTS_MODULE.ProjectForms',
        required: true,
        id: `landIsPrivate`,
        appendTo: 'self',
        hideExpression: "model.activity.isProponentOwner !== false"
      },
      {
        type: 'input',
        key: 'activity.eiaDepositPercentage',
        className: 'col-6',
        label: 'PROJECTS_MODULE.ProjectForms.eiaDepositPercentage',
        required: true,
        customTemplateOptions: {
          hideArrows: true,
          type: 'number'
        },
        max: 100,
        min: 0,
      },
      // {
      //   key: 'activity.directFinancialIncentivesReceived',
      //   type: 'checkbox',
      //   className: 'col-12',
      //   label: 'PROJECTS_MODULE.ProjectForms.subProjecteDirectIncentive'
      // },
      // {
      //   key: 'activity.conservationCreditReceived',
      //   type: 'checkbox',
      //   className: 'col-12',
      //   label: 'PROJECTS_MODULE.ProjectForms.subProjecteConservationCredit'
      // }
      {
        key: 'activity.indigenousLedBusiness',
        type: 'checkbox',
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.indigenousBusiness',
      },
    ],
  },
];
