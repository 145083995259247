/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./shared/components/loading/loading.component.ngfactory";
import * as i4 from "./shared/components/loading/loading.component";
import * as i5 from "./core/services/loader.service";
import * as i6 from "./app.component";
import * as i7 from "./core/store/store.service";
import * as i8 from "./shared/services/modal.service";
import * as i9 from "@ngx-translate/core";
import * as i10 from "@angular/platform-browser";
import * as i11 from "./shared/services/navigation.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-loading", [], null, null, null, i3.View_LoadingComponent_0, i3.RenderType_LoadingComponent)), i1.ɵdid(3, 49152, null, 0, i4.LoadingComponent, [i5.LoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i6.AppComponent, [i2.Router, i7.StoreService, i8.ModalService, i9.TranslateService, i10.Title, i5.LoaderService, i11.NavigationService, i2.ActivatedRoute], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i6.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
