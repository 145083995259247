import { IRawFormBase } from '@core/models/raw-form.types';
import { LOOKUP_OFFSET_QUANTIFICATION_METHOD_TYPE, LOOKUP_OFFSET_SEQUESTRATION_PROJECT_TYPE } from '@core/services/lookup.service';
import { SOURCE_DATA_TYPE } from '@shared/components/stepper/stepper.const';
import { ITableViewConfig, ROWS_PER_PAGE } from '@shared/models/table-view.model';
import { EDIT_PROJECT_PUBLIC_VISIBILITY, VIEW_PROJECT_PUBLIC_VISIBILITY } from '@core/models/permission.const';

export const PROJECT_ID_KEY = 'EditedProjectId';

export const PROJECT_TYPES = {
  AGGREGATED: 'aggregated',
  OFFSET: 'offset',
  SUB: 'sub',
  MASTER_RECEIVER: 'MASTER_RECEIVER'
};

export const SUBMIT_ACTIONS = [
  'SUBMIT',
  'RESUBMIT',
  'SUBMIT_PROJECT_ADDITIONS_TO_AGGREGATED_PROJECT',
];

export const PROJECT_QUANTIFICATION_DOCUMENT = 'project.quantification.document';

export const DOWNLOAD_TEMPLATE_TASK_LIST = [
  {
    id: 1,
    name: 'Download Template',
  },
];

export const DETAILS_FORM: IRawFormBase[] = [
  {
    fieldGroup: [
      {
        type: 'input',
        key: 'name',
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.projectNameEn',
        disabled: false,
        required: true,
        id: 'projectName',
        ignoreLocalFormDisabled: true
      },
      {
        type: 'input',
        key: 'name2',
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.projectNameFr',
        disabled: false,
        required: false,
        id: 'projectName2',
        ignoreLocalFormDisabled: true,
        expressionProperties: {
          'templateOptions.disabled': '(model.disabled && !model.editPublicVisibility)',
        },
      },
      {
        type: 'input',
        key: 'projectId',
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.projecId',
        disabled: true,
        formDisabled: true,
        required: true,
        hideExpression: '!model.projectId',
        id: 'projecId',
      },
      {
        className: 'col-12',
        type: 'textarea',
        key: 'description',
        label: 'PROJECTS_MODULE.ProjectForms.projectDescriptionen',
        disabled: false,
        required: true,
        id: 'projectDescription',
        // expressionProperties: {
        //   'templateOptions.disabled': '(model.disabled && !model.adminPermisson)',
        // },
      },
      {
        className: 'col-12',
        type: 'textarea',
        key: 'description2',
        label: 'PROJECTS_MODULE.ProjectForms.projectDescriptionfr',
        disabled: false,
        required: false,
        id: 'projectDescription2',
        expressionProperties: {
          'templateOptions.disabled': '(model.disabled && !model.editPublicVisibility)',
        },
      },
      {
        type: 'input',
        key: 'account.authorizedOfficial.name',
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.projectProponentName',
        formDisabled: true,
        required: true,
        id: 'projectProponentName',
        hideExpression: `!model.status || model.status === 'IN_PROCESS'`,
      },
      {
        className: 'col-12',
        parse: true,
        type: 'template',
        label: '',
      },
      // {
      //   className: "col-12",
      //   type: "textarea",
      //   key: "proponentContact", // value to be bind with AO Information should be displayed (i.e. AO Mailing address instead of the Org Address)
      //   label: 'PROJECTS_MODULE.ProjectForms.projectProponentContact',
      //   disabled: true,
      //   required: true,
      //   id: "projectProponentContact",
      // },
      {
        type: 'input',
        key: 'account.registrationNumber',
        className: 'col-12 mt-3',
        label: 'PROJECTS_MODULE.ProjectForms.projectProponentBusinessNumber',
        formDisabled: true,
        required: false,
        id: 'projectProponentBusinessNumber',
        hideExpression: `!model.status || model.status === 'IN_PROCESS'`,
      },
      {
        type: 'app-formly-datepicker',
        key: 'submissionDate',
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.projectSubmissionDate',
        disabled: true,
        required: true,
        id: 'projectSubmissionDate',
        hideExpression: '!model.submissionDate',
      },

      {
        key: 'projectRegistrationDate',
        type: 'app-formly-datepicker',
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.projectRegistrationDate',
        required: true,
        disabled: true,
        id: 'projectRegistrationDate',
        hideExpression: true,
      },
      {
        type: 'input',
        key: 'noOfSubProjects',
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.noOfSubProjects',
        disabled: true,
        required: true,
        id: 'noOfSubProjects',
        hideExpression: `model.type !== 'MASTER' && model.type !== 'MASTER_RECEIVER'`,
      },
    ],
  },
];

export const NEW_OFFSET_ACTIVITY_FORM = [
  {
    groupFields: true,
    label: 'PROJECTS_MODULE.ProjectForms.location',
    class: 'pl-2 mt-3',
    wrappers: ['simple-border'],
    fieldGroup: [
      {
        type: 'dict-select',
        key: 'provinceId',
        source: SOURCE_DATA_TYPE.PROVINCE_BY_COUNTRY,
        translatePrefix: 'COMMON.jurisdictionsList',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.province',
        disabled: false,
        required: true,
        id: `province`,
        appendTo: 'self',
      },
    ],
  },  
  {
    fieldGroupClassName: 'pl-4 row',
    fieldGroup: [
      {
        key: 'location.type',
        type: 'radio',
        className: 'col-6 d-none',
        label: 'PROJECTS_MODULE.ProjectForms.projectLocationBoundaries',
        dummyInputForLabel: true,
        required: true,
        defaultValue: 'DECIMAL',
        options: [
          // { value: 'PROJECTS_MODULE.ProjectForms.degreeLatLong', key: 'DEGREE' },
          { value: 'PROJECTS_MODULE.ProjectForms.decimalLatLong', key: 'DECIMAL' },
          {
            value: 'PROJECTS_MODULE.ProjectForms.fileLatLong',
            key: 'FILE',
            tooltip: 'PROJECTS_MODULE.ProjectForms.kmlFileTooltip',
            tooltipClass: 'tooltip-min-width',
          },
        ],
      },
    ],
  },
  {
    fieldGroupClassName: 'pl-4 row',
    fieldGroup: [
      {
        type: 'template',
        parse: true,
        translate: true,
        label: 'PROJECTS_MODULE.ProjectForms.decimalLatLong',
        className: 'col-12',
      },
      {
        type: 'input',
        key: 'location.latitudeDecimal',
        className: 'col-6',
        label: 'PROJECTS_MODULE.ProjectForms.latitude',
        formDisabled: false,
        changeExpr: 'this.inputChange($event, field)',
        required: true,
        id: 'latitude',
        hideExpression: "model.location.type !== 'DECIMAL'",
        // customTemplateOptions: {
        //   hideArrows: true,
        //   type: 'number'
        // }
        max: 90,
        min: -90,
      },
      {
        type: 'input',
        key: 'location.longitudeDecimal',
        className: 'col-6',
        label: 'PROJECTS_MODULE.ProjectForms.longitude',
        formDisabled: false,
        required: true,
        changeExpr: 'this.inputChange($event, field)',
        id: 'longitude',
        hideExpression: "model.location.type !== 'DECIMAL'",
        // customTemplateOptions: {
        //   hideArrows: true,
        //   type: 'number'
        // }
        max: 180,
        min: -180,
      },
      {
        type: 'button',
        className: 'mx-5',
        label: 'PROJECTS_MODULE.ProjectForms.browse',
        hideExpression: "model.location.type !== 'FILE'",
        subProject: false,
      },
      {
        type: 'template',
        label: '',
        className: 'col-6',
        parse: true,
        id: 'downloadLink',
        hideExpression: "model.location.type !== 'FILE'",
      },
      {
        type: 'input',
        key: 'location.file',
        className: 'col-1 file-input',
        // required: true,
        requiredExpression: '!model.location.kmlFileExists',
        id: 'mapFile',
        changeExpr: 'this.fileUploadEvent($event, field)',
        hideExpression: "model.location.type !== 'FILE'",
        customTemplateOptions: {
          type: 'file',
        },
      },
    ],
  },
  {
    fieldGroupClassName: 'pl-4 row',
    fieldGroup: [
      {
        type: 'template',
        parse: true,
        translate: true,
        label: 'PROJECTS_MODULE.ProjectForms.latitudeRequired',
        className: 'col-12',
        hideExpression: "model.location.type !== 'DEGREE'",
      },
      {
        type: 'input',
        key: 'location.latitudeDegree',
        className: 'col-4 mt-n3',
        formDisabled: false,
        required: true,
        id: 'latitudeDegree',
        hideExpression: "model.location.type !== 'DEGREE'",
        placeholder: 'PROJECTS_MODULE.ProjectForms.degree',
        customTemplateOptions: {
          hideArrows: true,
          type: 'number',
        },
        max: 90,
        min: -90,
      },
      {
        type: 'input',
        key: 'location.latitudeMinute',
        className: 'col-4 mt-n3',
        formDisabled: false,
        required: true,
        id: 'latitudeMin',
        hideExpression: "model.location.type !== 'DEGREE'",
        placeholder: 'PROJECTS_MODULE.ProjectForms.minutes',
        customTemplateOptions: {
          hideArrows: true,
          type: 'number',
        },
        max: 60,
        min: 0,
      },
      {
        type: 'input',
        key: 'location.latitudeSecond',
        className: 'col-4 mt-n3',
        formDisabled: false,
        required: true,
        id: 'latitudeSec',
        hideExpression: "model.location.type !== 'DEGREE'",
        placeholder: 'PROJECTS_MODULE.ProjectForms.seconds',
        customTemplateOptions: {
          hideArrows: true,
          type: 'number',
        },
        max: 60,
        min: 0,
      },
      {
        type: 'template',
        parse: true,
        translate: true,
        label: 'PROJECTS_MODULE.ProjectForms.longitudeRequired',
        className: 'col-12',
        hideExpression: "model.location.type !== 'DEGREE'",
      },
      {
        type: 'input',
        key: 'location.longitudeDegree',
        className: 'col-4 mt-n3',
        formDisabled: false,
        required: true,
        id: 'longitudeDegree',
        hideExpression: "model.location.type !== 'DEGREE'",
        placeholder: 'PROJECTS_MODULE.ProjectForms.degree',
        customTemplateOptions: {
          hideArrows: true,
          type: 'number',
        },
        max: 180,
        min: -180,
      },
      {
        type: 'input',
        key: 'location.longitudeMinute',
        className: 'col-4 mt-n3',
        formDisabled: false,
        required: true,
        id: 'longitudeMin',
        hideExpression: "model.location.type !== 'DEGREE'",
        placeholder: 'PROJECTS_MODULE.ProjectForms.minutes',
        customTemplateOptions: {
          hideArrows: true,
          type: 'number',
        },
        max: 60,
        min: 0,
      },
      {
        type: 'input',
        key: 'location.longitudeSecond',
        className: 'col-4 mt-n3',
        formDisabled: false,
        required: true,
        id: 'longitudeSec',
        hideExpression: "model.location.type !== 'DEGREE'",
        placeholder: 'PROJECTS_MODULE.ProjectForms.seconds',
        customTemplateOptions: {
          hideArrows: true,
          type: 'number',
        },
        max: 60,
        min: 0,
      },
    ],
  },
  {
    groupFields: false,
    wrappers: ['simple-border'],
    fieldGroupClassName: 'pl-4 row',
    fieldGroup: [
      {
        type: 'textarea',
        className: 'pl-3 col-12',
        key: 'municipality',
        label: 'PROJECTS_MODULE.ProjectForms.municipality',
        disabled: false,
        required: true,
        id: 'municipality',
      },
    ],
  },
  {
    groupFields: true,
    label: 'PROJECTS_MODULE.ProjectForms.projectDetails',
    class: 'pl-2 mt-3',
    wrappers: ['simple-border'],
    fieldGroup: [
      {
        key: 'activityStartDate',
        type: 'app-formly-datepicker',
        className: 'col-sm-12 col-md-6',
        label: 'PROJECTS_MODULE.ProjectForms.projectStartDate',
        required: true,
        id: 'projectStartDate',
      },
      {
        type: 'dict-select',
        key: 'offsetProtocol.id',
        source: SOURCE_DATA_TYPE.OFFSET_PROTOCOLS,
        label: 'PROJECTS_MODULE.ProjectForms.ghgOffsetProtocols',
        placeholder: 'PROJECTS_MODULE.ProjectForms.selectItem',
        disabled: false,
        required: true,
        id: `offsetProtocol`,
        appendTo: 'self',
      },
      {
        key: 'creditingPeriodStartDate',
        type: 'app-formly-datepicker',
        className: 'col-sm-12 col-md-6',
        label: 'PROJECTS_MODULE.ProjectForms.creditingStartDate',
        required: true,
        // disabled: true, // TODO: toggle disable based on role if admin then false.
        id: 'creditingPeriodStartDate',
        hideExpression: '!model._creditingPeriodStartDateVisible|| model.editParticipant',
        expressionProperties: {
          'templateOptions.disabled': '(model.disabled && !model.adminPermisson)',
        },
      },
      {
        key: 'creditingPeriodEndDate',
        type: 'app-formly-datepicker',
        className: 'col-sm-12 col-md-6',
        label: 'PROJECTS_MODULE.ProjectForms.creditingEndDate',
        required: true,
        // disabled: true, // TODO: toggle disable based on role if admin then false.
        id: 'creditingPeriodEndDate',
        hideExpression: '!model._creditingPeriodEndDateVisible || model.editParticipant',
        expressionProperties: {
          'templateOptions.disabled': '(model.disabled && !model.adminPermisson)',
        },
      },
      {
        // type: "dict-select",
        type: 'multi-checkbox',
        key: 'greenhouseGasIds',
        bindLabel: 'code',
        translatePrefix: 'COMMON.lookupFormData',
        source: SOURCE_DATA_TYPE.GREEN_HOUSE_GAS,
        label: 'PROJECTS_MODULE.ProjectForms.greenHouseGas',
        placeholder: 'PROJECTS_MODULE.ProjectForms.selectItem',
        dummyInputForLabel: true,
        disabled: false,
        required: true,
        // multiple: true,
        id: `greenhouseGas`,
        appendTo: 'self',
      },
    ],
  },
  {
    fieldGroupClassName: 'pl-4 row',
    fieldGroup: [
      {
        key: 'sequestrationProject',
        type: 'radio',
        className: 'col-6',
        apiOptions: true,
        dummyInputForLabel: true,
        apiService: LOOKUP_OFFSET_SEQUESTRATION_PROJECT_TYPE,
        label: 'PROJECTS_MODULE.ProjectForms.sequestrianProject',
        required: true,
      },
      {
        key: 'quantificationMethod',
        type: 'radio',
        className: 'col-6',
        label: 'PROJECTS_MODULE.ProjectForms.quantificationMethod',
        dummyInputForLabel: true,
        apiOptions: true,
        apiService: LOOKUP_OFFSET_QUANTIFICATION_METHOD_TYPE,
        required: true,
        expressionProperties: {
          'templateOptions.required': `model.sequestrationProject !== 'NOT_APPLICABLE'`,
        },
      },
      {
        key: 'projectPreviouslyRegistered',
        type: 'checkbox',
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.existingProject',
      },
      {
        type: 'input',
        key: 'previousProgramName',
        className: 'col-6',
        formDisabled: false,
        required: true,
        id: 'previousOffsetProgram',
        label: 'PROJECTS_MODULE.ProjectForms.previousOffsetProgram',
        hideExpression: '!model.projectPreviouslyRegistered',
      },
    ],
  },
  {
    groupFields: true,
    label: 'PROJECTS_MODULE.ProjectForms.emissionRemovalDetails',
    class: 'pl-2 mt-3',
    wrappers: ['simple-border'],
    fieldGroup: [
      {
        type: 'blurb',
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.ghgFieldsGuidanceText',
      },
      // {
      //   type: 'input',
      //   key: 'projectScenarioGhg',
      //   className: 'col-6',
      //   label: 'PROJECTS_MODULE.ProjectForms.projectbaselineScenario',
      //   disabled: false,
      //   required: true,
      //   customTemplateOptions: {
      //     type: 'formatted-number',
      //   },
      //   changeExpr: 'this.inputChange($event, field)',
      // },
      // {
      //   type: 'input',
      //   key: 'baselineScenarioGhg',
      //   className: 'col-6',
      //   label: 'PROJECTS_MODULE.ProjectForms.baselineScenario',
      //   disabled: false,
      //   required: true,
      //   customTemplateOptions: {
      //     type: 'formatted-number',
      //   },
      //   changeExpr: 'this.inputChange($event, field)',
      // },
      {
        type: 'input',
        key: 'estimatedScenarioGhg',
        className: 'col-6',
        label: 'PROJECTS_MODULE.ProjectForms.estimatedReductions',
        formDisabled: false,
        required: true,
        customTemplateOptions: {
          type: 'formatted-number',
        },
        changeExpr: 'this.inputChange($event, field)',
      },
    ],
  },
  {
    groupFields: true,
    label: 'PROJECTS_MODULE.ProjectForms.additionalProjectDetails',
    class: 'pl-2 mt-3',
    wrappers: ['simple-border'],
    fieldGroup: [
      {
        type: 'dict-select',
        className: 'col-12',
        key: 'directFinancialIncentivesReceived',
        dataProvided: true,
        items: [
          { id: true, name: 'yes' },
          { id: false, name: 'no' },
        ],
        label: 'PROJECTS_MODULE.ProjectForms.directIncentives',
        placeholder: 'selectItem',
        translatePrefix: 'PROJECTS_MODULE.ProjectForms',
        disabled: false,
        required: true,
        id: `directFinancialIncentivesReceived`,
        appendTo: 'self',
      },
      {
        type: 'input',
        key: 'programName',
        className: 'col-6',
        label: 'PROJECTS_MODULE.ProjectForms.programName',
        required: true,
        hideExpression: '!model.directFinancialIncentivesReceived'
      },
      {
        type: 'input',
        key: 'percentageCreditForegone',
        className: 'col-6',
        label: 'PROJECTS_MODULE.ProjectForms.percentageCreditForegone',
        required: false,
        hideExpression: '!model.directFinancialIncentivesReceived',
        customTemplateOptions: {
          hideArrows: true,
          type: 'number'
        },
        max: 100,
        min: 0,
      },
      {
        type: 'dict-select',
        className: 'col-12',
        key: 'conservationCreditReceived',
        dataProvided: true,
        items: [
          { id: true, name: 'yes' },
          { id: false, name: 'no' },
        ],
        label: 'PROJECTS_MODULE.ProjectForms.conservationCredits',
        placeholder: 'selectItem',
        disabled: false,
        translatePrefix: 'PROJECTS_MODULE.ProjectForms',
        required: true,
        id: `conservationCreditReceived`,
        appendTo: 'self',
      },
      {
        type: 'dict-select',
        className: 'col-12',
        key: 'isProponentOwner',
        dataProvided: true,
        items: [
          { id: true, name: 'yes' },
          { id: false, name: 'no' },
        ],
        label: 'PROJECTS_MODULE.ProjectForms.standaloneProjectProponentIsOwner',
        placeholder: 'selectItem',
        disabled: false,
        translatePrefix: 'PROJECTS_MODULE.ProjectForms',
        required: false,
        id: `proponentIsOwner`,
        appendTo: 'self',
      },
      {
        type: 'dict-select',
        className: 'col-12',
        key: 'isLandPrivate',
        dataProvided: true,
        items: [
          { id: true, name: 'yes' },
          { id: false, name: 'no' },
        ],
        label: 'PROJECTS_MODULE.ProjectForms.landIsPrivate',
        placeholder: 'selectItem',
        disabled: false,
        translatePrefix: 'PROJECTS_MODULE.ProjectForms',
        required: true,
        id: `landIsPrivate`,
        appendTo: 'self',
        hideExpression: "model.isProponentOwner !== false",
      },
      {
        type: 'input',
        key: 'eiaDepositPercentage',
        className: 'col-6',
        label: 'PROJECTS_MODULE.ProjectForms.eiaDepositPercentage',
        required: true,
        customTemplateOptions: {
          hideArrows: true,
          type: 'number',
        },
        max: 100,
        min: 0,
      },
      {
        key: 'indigenousLedBusiness',
        type: 'checkbox',
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.indigenousBusiness',
      },
      {
        key: 'confidentialBusiness',
        type: 'checkbox',
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.confidentialBusiness',
      },
    ],
  },
  {
    groupFields: true,
    label: 'PROJECTS_MODULE.ProjectForms.attestation',
    class: 'pl-2 mt-3',
    wrappers: ['simple-border'],
    fieldGroup: [
      {
        type: 'template',
        parse: true,
        translate: true,
        label: 'PROJECTS_MODULE.ProjectForms.proponentAttestation',
        className: 'col-12',
      },
      {
        key: 'attnExclusiveEntitlementToReductions',
        type: 'checkbox',
        className: 'col-12',
        required: true,
        requiredTrue: true,
        label: 'PROJECTS_MODULE.ProjectForms.proponentExclusioveEntitlement',
      },
      {
        key: 'attnNotFailedToComplyWithSectionOfAct',
        type: 'checkbox',
        required: true,
        requiredTrue: true,
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.proponentHasNecessaryAuthorizationsToCarryOutProjectActivities',
      },
      {
        key: 'attnEligibilityCriteriaOfProtocolAreMet',
        type: 'checkbox',
        required: true,
        requiredTrue: true,
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.proponentIsInComplianceWithLegislativeAndRegulatoryRequirements',
      },
      {
        key: 'attnOldRegNotCancelledVoluntaryReversal',
        type: 'checkbox',
        className: 'col-12',
        required: true,
        requiredTrue: true,
        label: 'PROJECTS_MODULE.ProjectForms.voluntaryReversalCancellation',
      },
      {
        key: 'attnNotConvictedOfOffenceUnderCriminalCode',
        type: 'checkbox',
        required: true,
        requiredTrue: true,
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.notConvictedForCriminalCode',
      },
      {
        key: 'attnProjectNotRegCreditForGhgReductions',
        type: 'checkbox',
        required: true,
        requiredTrue: true,
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.notRegisterdForReductionCredit',
      },
      {
        key: 'attnFreeFromConflictOfInterestRegulations',
        type: 'checkbox',
        required: true,
        requiredTrue: true,
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.ghgReductionIsAdditional',
      }
    ],
  },
];

export const PROJECT_DETAILS_TRANSFER_FORM = [
  {
    groupFields: true,
    label: 'PROJECTS_MODULE.ProjectForms.currentProponentDetails',
    fieldGroup: [
      {
        type: 'input',
        key: 'account.legalName',
        className: 'col-6',
        label: 'PROJECTS_MODULE.ProjectForms.organizationName',
        disabled: true,
        id: 'legalName',
      },
      {
        type: 'input',
        key: 'account.id',
        className: 'col-6',
        label: 'PROJECTS_MODULE.ProjectForms.accountId',
        disabled: true,
        id: 'accountId',
      },
      {
        type: 'input',
        key: 'account.organizationAddressCompiled',
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.organizationAddress',
        disabled: true,
        id: 'organizationAddress',
      },
      {
        type: 'input',
        key: 'account.authorizedAccountRepresenttiveName',
        className: 'col-6',
        label: 'PROJECTS_MODULE.ProjectForms.authorizedOfficialName',
        disabled: true,
        id: 'authorizedOfficialName',
      },
    ],
  },
  {
    groupFields: true,
    label: 'PROJECTS_MODULE.transferrequest.projectDetails',
    fieldGroup: [
      {
        type: 'input',
        key: 'name',
        className: 'col-6',
        label: 'PROJECTS_MODULE.ProjectForms.projectName',
        disabled: true,
        id: 'projectName',
      },
      {
        type: 'input',
        key: 'projectId',
        className: 'col-6',
        label: 'PROJECTS_MODULE.ProjectForms.projectId',
        disabled: true,
        id: 'projectId',
      },
      {
        type: 'input',
        key: 'aggregatedProjectName',
        className: 'col-6',
        label: 'PROJECTS_MODULE.ProjectForms.projectNameOfAggregation',
        disabled: true,
        id: 'aggregatedProjectName',
      },
      {
        type: 'input',
        key: 'aggregatedProjectId',
        className: 'col-6',
        label: 'PROJECTS_MODULE.ProjectForms.aggregatedProjectId',
        disabled: true,
        id: 'aggregatedProjectId',
      },
      {
        type: 'input',
        key: 'type',
        className: 'col-6',
        label: 'PROJECTS_MODULE.ProjectForms.projectApplicationType',
        disabled: true,
        id: 'type',
      },
    ],
  },
];

export const AGGREGATED_OFFSET_ACTIVITY_FORM = [
  {
    groupFields: true,
    label: 'PROJECTS_MODULE.ProjectForms.location',
    class: 'pl-2 mt-3',
    wrappers: ['simple-border'],
    fieldGroup: [
      {
        type: 'dict-select',
        key: 'provinceId',
        source: SOURCE_DATA_TYPE.PROVINCE_BY_COUNTRY,
        translatePrefix: 'COMMON.jurisdictionsList',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.province',
        disabled: false,
        required: true,
        id: `province`,
        appendTo: 'self'
      },
    ],
  },
  {
    fieldGroupClassName: 'pl-4 row',
    fieldGroup: [
      {
        type: 'template',
        label: '',
        className: 'col-6 pl-5',
        parse: true,
      },
    ],
  },
  {
    groupFields: true,
    label: 'PROJECTS_MODULE.ProjectForms.projectDetails',
    class: 'pl-2 mt-3',
    wrappers: ['simple-border'],
    fieldGroup: [
      {
        key: 'activityStartDate',
        type: 'app-formly-datepicker',
        className: 'col-sm-12 col-md-6',
        label: 'PROJECTS_MODULE.ProjectForms.projectStartDate',
        required: true,
        disabled: true,
        id: 'projectStartDate',
        placeholder: 'PROJECTS_MODULE.ProjectForms.autoPopulated',
      },
      {
        type: 'dict-select',
        key: 'offsetProtocol.id',
        source: SOURCE_DATA_TYPE.OFFSET_PROTOCOLS,
        label: 'PROJECTS_MODULE.ProjectForms.ghgOffsetProtocols',
        placeholder: 'PROJECTS_MODULE.ProjectForms.selectItem',
        disabled: false,
        required: true,
        id: `offsetProtocol`,
        appendTo: 'self',
      },
      {
        key: 'transferStartDate',
        type: 'app-formly-datepicker',
        className: 'col-sm-12 col-md-6',
        label: 'PROJECTS_MODULE.ProjectForms.effectiveProjectTransferDate',
        required: true,
        // disabled: true, // TODO: toggle disable based on role if admin then false.
        id: 'effectiveProjectTransferDate',
        hideExpression: "model.projectType !== 'MASTER_RECEIVER'",
        expressionProperties: {
          'templateOptions.disabled': "model.disabled",
        },
      },
      {
        key: 'creditingPeriodStartDate',
        type: 'app-formly-datepicker',
        className: 'col-sm-12 col-md-6',
        label: 'PROJECTS_MODULE.ProjectForms.creditingStartDate',
        required: true,
        // disabled: true, // TODO: toggle disable based on role if admin then false.
        id: 'creditingPeriodStartDate',
        hideExpression: '!model._creditingPeriodStartDateVisible || model.editParticipant',
        expressionProperties: {
          'templateOptions.disabled': '(model.disabled && !model.adminPermisson)',
        },
      },
      {
        key: 'creditingPeriodEndDate',
        type: 'app-formly-datepicker',
        className: 'col-sm-12 col-md-6',
        label: 'PROJECTS_MODULE.ProjectForms.creditingEndDate',
        required: true,
        // disabled: true, // TODO: toggle disable based on role if admin then false.
        id: 'creditingPeriodEndDate',
        hideExpression: '!model._creditingPeriodEndDateVisible || model.editParticipant',
        expressionProperties: {
          'templateOptions.disabled': '(model.disabled && !model.adminPermisson)',
        },
      },
      {
        // type: "dict-select",
        type: 'multi-checkbox',
        key: 'greenhouseGasIds',
        bindLabel: 'code',
        translatePrefix: 'COMMON.lookupFormData',
        source: SOURCE_DATA_TYPE.GREEN_HOUSE_GAS,
        label: 'PROJECTS_MODULE.ProjectForms.greenHouseGas',
        placeholder: 'PROJECTS_MODULE.ProjectForms.selectItem',
        dummyInputForLabel: true,
        disabled: false,
        required: true,
        multiple: true,
        id: `greenhouseGas`,
        appendTo: 'self',
      },
    ],
  },
  {
    fieldGroupClassName: 'pl-4 row',
    fieldGroup: [
      {
        key: 'sequestrationProject',
        type: 'radio',
        className: 'col-6',
        apiOptions: true,
        apiService: LOOKUP_OFFSET_SEQUESTRATION_PROJECT_TYPE,
        label: 'PROJECTS_MODULE.ProjectForms.sequestrianProject',
        dummyInputForLabel: true,
        required: true,
      },
      {
        key: 'quantificationMethod',
        type: 'radio',
        className: 'col-6',
        label: 'PROJECTS_MODULE.ProjectForms.quantificationMethod',
        dummyInputForLabel: true,
        apiOptions: true,
        apiService: LOOKUP_OFFSET_QUANTIFICATION_METHOD_TYPE,
        required: true,
        expressionProperties: {
          'templateOptions.required': `model.sequestrationProject !== 'NOT_APPLICABLE'`,
        },
      },
      {
        key: 'projectPreviouslyRegistered',
        type: 'checkbox',
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.existingProject',
      },
      {
        key: 'indigenousLedBusiness',
        type: 'checkbox',
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.indigenousBusiness',
      },
      {
        type: 'input',
        key: 'previousProgramName',
        className: 'col-6',
        formDisabled: false,
        required: true,
        id: 'previousProgramName',
        label: 'PROJECTS_MODULE.ProjectForms.previousOffsetProgram',
        hideExpression: '!model.projectPreviouslyRegistered',
      },
    ],
  },
  {
    groupFields: true,
    label: 'PROJECTS_MODULE.ProjectForms.emissionRemovalDetails',
    class: 'pl-2 mt-3',
    wrappers: ['simple-border'],
    fieldGroup: [
      {
        type: 'blurb',
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.ghgFieldsGuidanceText',
      },
      // {
      //   type: 'input',
      //   key: 'projectScenarioGhg',
      //   className: 'col-12',
      //   label: 'PROJECTS_MODULE.ProjectForms.projectbaselineScenario',
      //   formDisabled: true,
      //   required: true,
      //   placeholder: 'PROJECTS_MODULE.ProjectForms.autoPopulated',
      //   customTemplateOptions: {
      //     type: 'formatted-number',
      //   },
      // },
      // {
      //   type: 'input',
      //   key: 'baselineScenarioGhg',
      //   className: 'col-12',
      //   label: 'PROJECTS_MODULE.ProjectForms.baselineScenario',
      //   formDisabled: true,
      //   required: true,
      //   placeholder: 'PROJECTS_MODULE.ProjectForms.autoPopulated',
      //   customTemplateOptions: {
      //     type: 'formatted-number',
      //   },
      // },
      {
        type: 'input',
        key: 'estimatedScenarioGhg',
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.estimatedReductions',
        formDisabled: true,
        required: true,
        placeholder: 'PROJECTS_MODULE.ProjectForms.autoPopulated',
        customTemplateOptions: {
          type: 'formatted-number',
        },
      },
    ],
  },
];

export const AGGREGATED_ATTN_DECLARATION_FORM: IRawFormBase[] = [
  {
    groupFields: true,
    label: 'PROJECTS_MODULE.ProjectForms.additionalProjectDetails',
    class: 'pl-2 mt-3',
    wrappers: ['simple-border'],
    fieldGroup: [
      {
        key: 'confidentialBusiness',
        type: 'checkbox',
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.confidentialBusiness',
      },
      {
        key: 'indigenousLedBusiness',
        type: 'checkbox',
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.indigenousBusiness',
        hideExpression: '!model.showIndigenousLedBusiness',
      },
    ],
  },
  {
    groupFields: true,
    label: 'PROJECTS_MODULE.ProjectForms.attestation',
    class: 'pl-2 mt-3',
    wrappers: ['simple-border'],
    fieldGroup: [
      {
        type: 'template',
        parse: true,
        translate: true,
        label: 'PROJECTS_MODULE.ProjectForms.proponentAttestation',
        className: 'col-12',
      },
      {
        key: 'attnProponentIsAuthorized',
        type: 'checkbox',
        className: 'col-12',
        required: true,
        requiredTrue: true,
        label: 'PROJECTS_MODULE.ProjectForms.attnProponentIsAuthorizedAggregated',
      },
      {
        key: 'attnExclusiveEntitlementToReductions',
        type: 'checkbox',
        className: 'col-12',
        required: true,
        requiredTrue: true,
        label: 'PROJECTS_MODULE.ProjectForms.proponentExclusioveEntitlementAggregated',
      },
      {
        key: 'attnOldRegNotCancelledVoluntaryReversal',
        type: 'checkbox',
        className: 'col-12',
        required: true,
        requiredTrue: true,
        label: 'PROJECTS_MODULE.ProjectForms.voluntaryReversalCancellationAggregated',
      },
      {
        key: 'attnNotFailedToComplyWithSectionOfAct',
        type: 'checkbox',
        required: true,
        requiredTrue: true,
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.nonFailuerOfSection181Aggregated',
      },
      {
        key: 'attnNotConvictedOfOffenceUnderCriminalCode',
        type: 'checkbox',
        required: true,
        requiredTrue: true,
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.notConvictedForCriminalCodeAggregated',
      },
      {
        key: 'attnEligibilityCriteriaOfProtocolAreMet',
        type: 'checkbox',
        required: true,
        requiredTrue: true,
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.notGuiltyUnderSection380Aggregated',
      },
      {
        key: 'attnProjectNotRegCreditForGhgReductions',
        type: 'checkbox',
        required: true,
        requiredTrue: true,
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.notRegisterdForReductionCreditAggregated',
      },
      {
        key: 'attnFreeFromConflictOfInterestRegulations',
        type: 'checkbox',
        required: true,
        requiredTrue: true,
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.freeFromCOIAggregated',
      }
    ],
  },
];

export const PROJECT_TYPE_FORM: IRawFormBase = {
  [PROJECT_TYPES.OFFSET]: NEW_OFFSET_ACTIVITY_FORM,
  [PROJECT_TYPES.AGGREGATED]: AGGREGATED_OFFSET_ACTIVITY_FORM,
  [PROJECT_TYPES.MASTER_RECEIVER]: AGGREGATED_OFFSET_ACTIVITY_FORM,
};

export const PROJECT_APPLICATION_TYPES = {
  [PROJECT_TYPES.OFFSET]: 'NORMAL',
  [PROJECT_TYPES.AGGREGATED]: 'MASTER',
  [PROJECT_TYPES.SUB]: 'SUB',
  [PROJECT_TYPES.MASTER_RECEIVER]: 'MASTER_RECEIVER',
};

export const PROJECT_TYPE_MAP = {
  NORMAL: PROJECT_TYPES.OFFSET,
  MASTER: PROJECT_TYPES.AGGREGATED,
  SUB: PROJECT_TYPES.SUB,
  MASTER_RECEIVER: PROJECT_TYPES.MASTER_RECEIVER
};

export const ADMIN_REMARKS_FIELDS: IRawFormBase[] = [{
  groupFields: true,
  label: 'PROJECTS_MODULE.ProjectForms.adminReview',
  class: 'pl-2 mt-3',
  wrappers: ['simple-border'],
  fieldGroup: [
    {
      className: 'col-12',
      type: 'textarea',
      key: 'adminRemark',
      label: 'PROJECTS_MODULE.ProjectForms.adminRemarks',
      disabled: false,
      required: false,
      id: 'adminRemark',
      expressionProperties: {
        'templateOptions.disabled': '!model.adminPermisson',
      },
    },
    {
      key: 'discloseComment',
      type: 'checkbox',
      className: 'col-12',
      label: 'PROJECTS_MODULE.ProjectForms.discloseComment',
      hideExpression: '!model.adminPermisson',
      expressionProperties: {
        'templateOptions.disabled': '!model.adminPermisson',
      },
    },
  ],
}];

export const NEED_MORE_INFO_FORM: IRawFormBase[] = [
  {
    groupFields: true,
    label: 'PROJECTS_MODULE.ProjectForms.adminReview',
    class: 'pl-2 mt-3',
    wrappers: ['simple-border'],
    fieldGroup: [
      {
        type: 'dict-select',
        className: 'col-12',
        key: 'activity.isPublic',
        dataProvided: true,
        items: [
          { id: true, name: 'yes' },
          { id: false, name: 'no' },
        ],
        label: 'PROJECTS_MODULE.ProjectForms.makePublic',
        placeholder: 'selectItem',
        disabled: false,
        translatePrefix: 'PROJECTS_MODULE.ProjectForms',
        required: false,
        id: `isPublicProject`,
        defaultValue: null,
        clearable: false,
        permissions: {
          view: [VIEW_PROJECT_PUBLIC_VISIBILITY],
          edit: [EDIT_PROJECT_PUBLIC_VISIBILITY],
        },
      },
      {
        className: 'col-12',
        type: 'textarea',
        key: 'adminRemark',
        label: 'PROJECTS_MODULE.ProjectForms.adminRemarks',
        disabled: false,
        required: false,
        id: 'adminRemark',
        expressionProperties: {
          'templateOptions.disabled': '!model.adminPermisson',
        },
      },
      {
        key: 'discloseComment',
        type: 'checkbox',
        className: 'col-12',
        label: 'PROJECTS_MODULE.ProjectForms.discloseComment',
        hideExpression: '!model.adminPermisson',
        expressionProperties: {
          'templateOptions.disabled': '!model.adminPermisson',
        },
      },
    ],
  },
];

export const NEED_MORE_iNFOR_CONFIG: ITableViewConfig = {
  translationPrefix: 'COMMON.custom_messages',
  paginator: true,
  rowsPerPage: ROWS_PER_PAGE,
  editService: '/offset-service/project/review',
  columns: [
    {
      field: 'id',
      header: 'id',
      sortField: 'id',
      width: '200px',
      editable: false,
      fieldType: 'readonly-input',
      inputType: 'number',
      editRowField: false,
    },
    {
      field: 'action',
      header: 'action',
      sortField: 'action',
      width: '200px',
      editable: false,
      fieldType: 'readonly-input',
      inputType: 'number',
      editRowField: false,
    },
    {
      field: 'needMoreInfoComment',
      header: 'comment',
      sortField: 'comment',
      width: '200px',
      editable: false,
      fieldType: 'textarea',
      inputType: 'string',
      editRowField: true,
      className: 'col-12',
    },
  ],
};

export const DISABLED_FIELDS_ON_REPORT_UPDATE = [
  'eiaDepositPercentage',
];

export const PENDING_REG_KEY = 'pendingRegOrigin';

export const INCOMING_PROJECT_FIELDS = [
  {
    class: 'pl-4',
    fieldGroup: [
      {
        type: 'input',
        key: 'accountId',
        className: 'col-4',
        label: 'PROJECTS_MODULE.ProjectForms.accountID',
        disabled: false,
        required: true,
        id: 'accountID',
        ignoreLocalFormDisabled: true,
        maxLength: 19,
      },
      {
        type: 'input',
        key: 'projectProjectId',
        className: 'col-4',
        label: 'PROJECTS_MODULE.ProjectForms.projectProjectId',
        disabled: false,
        required: true,
        id: 'projectProjectId',
        ignoreLocalFormDisabled: true
      },
      {
        type: 'button',
        id: 'searchProject',
        className: 'col-0 mr-2',
        label: 'PROJECTS_MODULE.ProjectForms.addProject',
        customTemplateOptions: {},
      },
      {
        type: 'button',
        id: 'clearProject',
        className: 'p-0',
        label: 'PROJECTS_MODULE.ProjectForms.clear',
        customTemplateOptions: {},
      },
    ],
  },
];
