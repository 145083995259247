import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IApp } from '@core/models/app.interfaces';
import { UnitFilterModel } from '@core/models/filter.model';
import { StoreService } from '@core/store/store.service';
import { environment } from '@env/environment';
import { ADMIN_WORKFLOW_CONFIG } from '@module/admin/services/admin-management.service';
import { IOkResponse } from '@module/registration/registration.model';
import {
  statusUriMap, CREDIT_CANCELLATION
} from '@module/unit-management/components/unit-list/unit-list.const';
import { IRecognizedCreditRemittanceData } from '@module/unit-management/unit-management.model';
import { TranslateService } from '@ngx-translate/core';
import { IActionOptionsModel } from '@shared/models/table-filter.models';
import { AbstractPaginationService } from '@shared/services/abstract-pagination-service';
import { HelperService } from "@shared/services/helper.service";
import { Observable } from 'rxjs';
import { cancelAction } from '@core/models/filter-types.model';
import { CFR_CALCULATED_CI_VOLUME_FLD } from '@module/unit-management/components/credit-transfer-form/credit-transfer-form.const';
import { ERP_CARBON_INTENSITY_FLD } from '@shared/components/steps/constants/erp-application.const';
import { NOE_CREDIT_STATUSES } from '@module/unit-management/unit-management.const';
import { ICreditCreationReportGetAlphNumCI } from '@module/credit-creation-reports/credit-creation-reports.types';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { ModalService } from '@shared/services/modal.service';

export const CREDIT_SERVICE = '/obps-service';
export const UNIT_SEARCH = '/unit/findAll';
export const UNIT_RECOG_DATA = '/obps-service/creditAction/createReconizedUnit';
export const UPDATE_UNIT_DATA = '/obps-service/creditAction/splitUnit';
export const UNIT_DETAIL = '/creditAction/loadByUnitId/';
export const CREDIT_ACTION_DETAIL = '/creditAction/loadByCreditActionId/';
export const FIND_RECOGNIZED_UNIT = '/remittanceAction/findRecognizeUnit/';
export const DICT_UNIT_CLASS_URI = '/account-service/lookup/getRuUnitClass';
export const DICT_COMPLIANCE_YEAR_URI = '/account-service/lookup/compliancePeriods';
export const DICT_GHG_YEAR_URI = '/account-service/lookup/getGhgYears';
export const DICT_OFFSET_PROTOCOL_URI = '/account-service/lookup/getOffsetProtocol';
export const DICT_OFFSET_PROTOCOL_JURISDTICTION_URI = '/account-service/lookup/getOffsetProtocol/';
export const DICT_OFFSET_PROTOCOL_VERSION_URI = '/account-service/lookup/getOffsetProtocolVersion';
export const DICT_ACCOUNT_NAMES_URI = '/account-service/lookup/facilityNames';
export const GET_OBLIGATIONS_URI = '/registry-server/obligation/getAccountObligations/';
export const DICT_JURISDICTIONS_URI = '/account-service/lookup/getRecognizeUnitJurisdiction';
export const DICT_SINGLE_SERIAL_NUMBER = '/account-service/lookup/isSingleSerialNumberFormat/';
export const OBLIGATION_BY_OBLIGATION_ID = '/registry-server/obligation/getByObligationId/';
export const RU_REMITTANCE_REPORT = '/registry-server/report/getRuRemittanceReport';
export const NOTES_SEARCH = '/offset-service/notes/search';
export const EIA_UNIT_SEARCH = '/offset-service/eia';
export const ID_STATUSES = [
  'PENDING_ISSUANCE_ADMIN',
  'PENDING_ISSUANCE_ADMIN1',
  'CREDIT_LIST',
  ...NOE_CREDIT_STATUSES,
];

const ACCOUNT_DETAILS = '/account-service/account/detail/';
const OTHER_ACCOUNT_DETAILS = '/account-service/account/otherAccountDetail/';
const GET_BULK_ACTION_ID = '/obps-service/creditAction/search';

const REQ_INSUFFICIENT_CREDIT_TO_CANCEL = '/obps-service/unit/requestInsufficientCreditsToCancel';
const UNIT_GET_INSUFFICIENT_CREDIT = '/obps-service/unit/getInsufficientCredit/';
const UNIT_INSUFFICIENT_CREDIT_SUBMISSION = '/obps-service/unit/insufficientCreditSubmission';
const UNIT_INSUFFICIENT_CREDIT_NOTICE_UPDATE = '/obps-service/unit/sendNoticeForCreditUpdate';
const UNIT_CANCEL_EXCESS_CREDIT = '/obps-service/unit/cancelExcessCredits';

const CHECK_FOR_ACTIVE_CCM = '/cfr-service/creditClearance/checkCreditCcmSetup';

export const CCR_FUEL_ENERGY_DENSITY_URI = '/account-service/energyDensity/getForFuel';
export const CCR_REFERENCE_CARBON_INTENSITY_FOR_FUEL = '/cfr-service/creditCreationReport/getReferencedCarbonIntensityForFuel';
export const CCR_CARBON_INTENSITY_IDS_LOOKUP_URI = '/cfr-service/application/lookupCarbonIntensityIDs';
@Injectable({
  providedIn: 'root',
})
export class UnitManagementService extends AbstractPaginationService {

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private router: Router,
    private helperService: HelperService,
    private store: StoreService,
    private modalService: ModalService
  ) {
    super();
  }

  getAll(pagination: IApp.IPagination, filterState: UnitFilterModel = {}, uri =`${CREDIT_SERVICE}${UNIT_SEARCH}` ): Observable<IApp.IUnitPage> {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    return this.http.post<IApp.IUnitPage>(`${ environment.serviceUrl }${ uri ? uri : (CREDIT_SERVICE+UNIT_SEARCH)}`, filterState, { params });
   // return this.http.post<IApp.IUnitPage>(`http://localhost:8088/api${ CREDIT_SERVICE }${ UNIT_SEARCH }`, filterState, { params });
  }

  creditAction( uri: string, payload: any): Observable<IOkResponse> {
   return this.http.post<IOkResponse>(`${ environment.apiUrl }${ CREDIT_SERVICE }${ uri }`, payload);
   // return this.http.post<IOkResponse>(`http://localhost:8088/api${ CREDIT_SERVICE }${ uri }`, payload);
  }

  pdfPreviewAction(uri: string, payload: any, expectErrors = false): Observable<any> {
    return this.http.post<any>
    (`${ environment.apiUrl }${ CREDIT_SERVICE }${ uri }`, payload,/**,
    {
        observe: 'response',
        responseType: expectErrors ? 'json' : 'blob' as 'json',
      }**/);
  }

  creditRecognizedAction( uri: string, payload: any): Observable<IOkResponse> {
    return this.http.post<IOkResponse>(`${ environment.apiUrl }${ CREDIT_SERVICE }${ uri }`, payload);
   // return this.http.post<IOkResponse>(`http://localhost:8088/api${ CREDIT_SERVICE }${ uri }`, payload);
   }

  getUnitDetails(unitId: number): Observable<IApp.ICreditActionData> {
    return this.http.get<IApp.ICreditFormAction>(`${ environment.apiUrl }${ CREDIT_SERVICE }${ UNIT_DETAIL }${unitId}`);
  //  return this.http.get<IApp.ICreditActionData>(`http://localhost:8088/api${ CREDIT_SERVICE }${ UNIT_DETAIL }${unitId}`);
  }

  loadByCreditActionId(actionId: number): Observable<IApp.ICreditActionData> {
     return this.http.get<IApp.ICreditFormAction>(`${ environment.apiUrl }${ CREDIT_SERVICE }${ CREDIT_ACTION_DETAIL }${actionId}`);
    // return this.http.get<IApp.ICreditActionData>(`http://localhost:8088/api${ CREDIT_SERVICE }${ CREDIT_ACTION_DETAIL }${actionId}`);
   }

  getRecognizedUnit(unitId: number): Observable<IRecognizedCreditRemittanceData> {
    return this.http.get<IRecognizedCreditRemittanceData>(`${ environment.apiUrl }${ CREDIT_SERVICE }${ FIND_RECOGNIZED_UNIT }${unitId}`);
  // return this.http.get<IRecognizedCreditRemittanceData>(`http://localhost:8088/api${ CREDIT_SERVICE }${ FIND_RECOGNIZED_UNIT }${unitId}`);
  }

  getUnitType(): Observable<IApp.IDict[]> {
    return this.http.get<IApp.IDict[]>(`${ environment.apiUrl }${ DICT_UNIT_CLASS_URI }`);
  }

  getComplianceYear(): Observable<IApp.IDict[]> {
    return this.http.get<IApp.IDict[]>(`${ environment.apiUrl }${ DICT_COMPLIANCE_YEAR_URI }`);
  }

  getGhgYear(): Observable<IApp.IDict[]> {
    return this.http.get<IApp.IDict[]>(`${ environment.apiUrl }${ DICT_GHG_YEAR_URI }`);
  }

  getOffsetProtocol(): Observable<IApp.IDict[]> {
    return this.http.get<IApp.IDict[]>(`${ environment.apiUrl }${ DICT_OFFSET_PROTOCOL_URI }`);
  }

  getOffsetProtocolByJursidiction(jurisidiction: number): Observable<IApp.IDict[]> {
    return this.http.get<IApp.IDict[]>(`${ environment.apiUrl }${ DICT_OFFSET_PROTOCOL_JURISDTICTION_URI }${jurisidiction}`);
  }

  getOffsetProtocolVersion(): Observable<IApp.IDict[]> {
    return this.http.get<IApp.IDict[]>(`${ environment.apiUrl }${ DICT_OFFSET_PROTOCOL_VERSION_URI }`);
  }

  getAccountNames(): Observable<IApp.IDict[]> {
    return this.http.get<IApp.IDict[]>(`${ environment.apiUrl }${ DICT_ACCOUNT_NAMES_URI }`);
  }

  getObligation(accountId: number) {
    return this.http.get<IApp.IDict[]>(`${ environment.apiUrl }${ GET_OBLIGATIONS_URI }${accountId}`);
  }

  getProgramWorkflowConfig() {
    const params = new HttpParams();
    return this.http.post(`${ environment.apiUrl }${ ADMIN_WORKFLOW_CONFIG }`, { params });
  }

  getRecognizeUnitJurisdiction(): Observable<IApp.IDict[]> {
    return this.http.get<IApp.IDict[]>(`${ environment.apiUrl }${ DICT_JURISDICTIONS_URI }`);
  }

  getRUAll(pagination: IApp.IPagination, filterState: UnitFilterModel = {}): Observable<IApp.IUnitPage> {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    return this.http.post<IApp.IUnitPage>(`${ environment.apiUrl }${ RU_REMITTANCE_REPORT }`, filterState, { params });
   // return this.http.post<IApp.IUnitPage>(`http://localhost:8088/api${ CREDIT_SERVICE }${ UNIT_SEARCH }`, filterState, { params });
  }

  isSingleSerialNumberFormat(id: number): Observable<boolean> {
    return this.http.get<boolean>(`${ environment.apiUrl }${ DICT_SINGLE_SERIAL_NUMBER }${id}`);
  }

  getObligationByObligationID(obligationId: number): Observable<any> {
    const url = `${ environment.apiUrl }${ OBLIGATION_BY_OBLIGATION_ID }${ obligationId }`;
    return this.http.get<any>(url);
  }

  public updateUnitData(payload: any): Observable<IOkResponse> {
    return this.http.post<IOkResponse>(`${ environment.apiUrl }${ UPDATE_UNIT_DATA }`, payload);
  }

  validateQuantity(field, errorMsg = 'quantityMustBePositiveNumber') {
    if (isNaN(field.formControl.value) || field.formControl.value % 1 != 0
                        || field.formControl.value <= 0) {
                        field.formControl.setErrors({ 'server-side': this.translate.instant(errorMsg) });
                      }
  }

  calculateEIAQuantity(quantity, percentage) {
    if(quantity && percentage){
      return  Math.ceil((quantity * percentage) / 100);
    }
    return null;
  }

  validateQuantityWithZero(field, quantity: number) {
    if (isNaN(field.formControl.value) || field.formControl.value % 1 != 0) {
        field.formControl.setErrors({ 'server-side': this.translate.instant('quantityMustBePositiveNumber') });
      } else if (quantity < field.formControl.value) {
        field.formControl.setErrors({ 'server-side': this.translate.instant('refundQuantityMustBeLessThanUnitQuantity') });
      }
  }

  onActionSelected(action: IActionOptionsModel, selectedUnits: IApp.IUnitData[]): IApp.ICreditActionData {
    const creditActionData: IApp.ICreditActionData = {};
    if (selectedUnits) {
      if(selectedUnits['ocrUnitList']){
        selectedUnits = selectedUnits['ocrUnitList'];
      }
    creditActionData.creditBlockList = selectedUnits.map(unit => (
      {
        unitId: unit.id,
        facilityName: unit.facilityName,
        quantity: unit.quantity,
        unitClassCode: unit.unitClassCode,
        standardId: unit.standardId,
        version: unit.version,
        issuanceDateStr: unit.issuanceDateStr,
        remittedComplianceYear: unit.remittedComplianceYear,
        serialNumber: unit.serialNumber,
        complianceYear: unit.complianceYear,
        accountId: unit.accountId,
        projectProjectId: unit.projectProjectId,
        projectId: unit.projectId,
        projectName: unit.projectName,
        issuanceYear: unit.issuanceDateStr ? unit.issuanceDateStr.substring(0, 4) : null,
        protocol: unit.protocol,
        jurisdictionStr: unit.jurisdictionStr,
        projectType: unit.project && unit.project.activity && unit.project.activity.activityType ?
          unit.project.activity.activityType.code : null,
        creditClass: unit.creditClass,
        creditGroupId: unit.creditGroupId,
        creditTypeCode: unit.creditTypeCode,
        creditGroupName: unit.creditGroupName,
        creditTypeId: unit.creditTypeId,
        fuelType: unit.fuelType,
        fuelId: unit.fuelId,
        issuedQuantity: unit.issuedQuantity,
        creditCreationReportDetailDto: unit.creditCreationReportDetailDto,
        // [CFR_CALCULATED_CI_VOLUME_FLD]: unit.creditGroupName.includes('VMG') || unit.creditGroupName.includes('VMD') ? unit.associatedVolume: null,
        associatedVolume: unit.creditGroupName && (unit.creditGroupName.includes('VMG') || unit.creditGroupName.includes('VMD')) ? unit.associatedVolume: null,
        [ERP_CARBON_INTENSITY_FLD]: unit.finalCi,
        approvedCliValue: unit.finalCi,
      }
    ));

    creditActionData.discloseComment = false;
    creditActionData.withdrawn = false;
    creditActionData.documentList = [];
    creditActionData.id = null;

    if (selectedUnits && selectedUnits.length > 0) {
      const unit = selectedUnits[0];
      creditActionData.standardId = unit.standardId;
      creditActionData.complianceYear = unit.remittedComplianceYear;
      creditActionData.accountId = unit.accountId;
      creditActionData.facilityName = unit.facilityName;
      creditActionData.certificateNumber = unit.certificateNumber;
      creditActionData.issuedQuantity = unit.issuedQuantity;
      creditActionData.jurisdiction = unit.jurisdiction;

      if (unit.account) {
        creditActionData.sourceAccount = {
          ...creditActionData.sourceAccount,
          jurisdiction: unit.account.jurisdiction,
          legalName: unit.account.legalName,
          englishTradeName: unit.account.englishTradeName,
          frenchTradeName: unit.account.frenchTradeName,
          registrationNumber: unit.account.registrationNumber,
          civicAddress: unit.account.civicAddress,
          postalAddress: unit.account.postalAddress,
        };

        if (unit.account.contactPerson) {
          const contactPerson = unit.account.contactPerson;
          creditActionData.sourceAccount = {
            ...creditActionData.sourceAccount,
            contactPerson: {
              civicAddress: contactPerson.civicAddress,
              postalAddress: contactPerson.postalAddress,
              name: contactPerson.name,
              title: contactPerson.title,
              businessTelephone: contactPerson.businessTelephone,
              email: contactPerson.email,
            }
          };
        }

        if (this.store.user.isCFR() && unit.account.authorizedAccountRepresenttive) {
          const authorizedOfficial = unit.account.authorizedAccountRepresenttive;
          creditActionData.sourceAccount = {
            ...creditActionData.sourceAccount,
            authorizedOfficial: {
              fullName: authorizedOfficial.fullName,
              title: authorizedOfficial.title,
              businessTelephone: authorizedOfficial.businessTelephone,
              email: authorizedOfficial.email,
              civicAddress: authorizedOfficial.civicAddress,
              postalAddress: authorizedOfficial.postalAddress,
            }
          };
        }
      }
    }
  }
    return creditActionData;
  }

  viewUnitDetails( unitClassCode: string, unit: IApp.IUnitData, eia = false, status: string = null) {
    if (unit.status === 'PROPOSED_REMITTANCE' && !this.store.user.checkIfTransactionProposer(unit.accountId)) {
      const creditActionData: IApp.ICreditActionData = {};
      creditActionData.id = unit.creditActionId;
      this.router.navigate(this.helperService.getTranslatedPath(
        `/obligation-management/remittance/SCR/${unit.obligationId}/${unit.creditActionId}`),
       { state: { data: creditActionData } });
    } else {
        const prefix = status ? status : unit.status === 'REJECTED' ? unit.actionType : unit.status;
        let key = `${unitClassCode}_${unit.status}`;
        if (prefix !== null) {
          key = `${unitClassCode}_${prefix}`;
        }
        if (unit.status === 'REFUND_REQUESTED' || unit.status === 'PENDING_REMIT_REVIEW_REFUND'
         || unit.status === 'PENDING_REMIT_REVIEW_REFUND1') {
         key = `${key}_${unit.actionType}`;
      }
        let id: any = unit.unitClassCode === 'RU' ||
          ID_STATUSES.includes(unit.status) || key === 'SCR_REJECTED'
          ? unit.id : unit.creditActionId;
        const creditActionData: IApp.ICreditActionData = {};
        creditActionData.id = id;
        if (!id || unit.status === 'ACTIVE' || status && status === 'ACTIVE' ||
            unit.status === 'PLEDGED' ||
            NOE_CREDIT_STATUSES.includes(unit.status)) {
          id = 'unit/' + unit.id;
        }

        let scrKey = key.replace('OCR', 'SCR');
        scrKey = scrKey.replace('OFFSET', 'SURPLUS');
        const uri = statusUriMap[key] ? statusUriMap[key]  : statusUriMap[scrKey] ;

        let path = `/unit-management/list${uri}`;


        if(path.indexOf(CREDIT_CANCELLATION) > -1 && eia){
          path = `${path}-eia`;
        }
        path = `${path}/${id}`;
        creditActionData.refundQuantity = unit.refundQuantity;
        this.router.navigate(this.helperService.getTranslatedPath(path), { state: { data: creditActionData, status: unit.status } });
    }
  }


  public addCheckBoxAction(data: IApp.IUnitData[] , tableDetails){
    const actionModel =   tableDetails.tableFilter.actionModel;
    actionModel[1].options = [];
    if(data.length > 0 && data[data.length -1].status === 'ACTIVE'){
      actionModel[1].options.push(cancelAction);
    }
    return actionModel;
  }

  public getDiscussions(id, dir = 'DESC'): Observable<any> {
    let params = new HttpParams();
    params = params.append('dir', dir);

    return this.http.post(
      `${environment.apiUrl}${NOTES_SEARCH}`,
      { entityId: id, entityType: 'CREDIT_ACTION' },
      { params });
  }

  getAccountDetails(id: number, otherAccount?: any): Observable<any> {
    let url = `${environment.apiUrl}${ACCOUNT_DETAILS}${id}`;
    // if (otherAccount) {
    //   url += `?otherAccount=${otherAccount}`;
    // }
    return this.http.get(url);
  }

  getOtherAccountDetails(id: number){
   return this.http.get(`${environment.apiUrl}${OTHER_ACCOUNT_DETAILS}${id}`);
  }

  getBulkActionId(accountId) {
    return this.http.post(`${environment.apiUrl}${GET_BULK_ACTION_ID}`, {accountId});
  }

  sendInsufficientCreditNotice(payload) {
    return this.http.post(`${environment.apiUrl}${REQ_INSUFFICIENT_CREDIT_TO_CANCEL}`, payload);
  }

  getInsufficientCreditById(id) {
    return this.http.get(`${environment.apiUrl}${UNIT_GET_INSUFFICIENT_CREDIT}${id}`);
  }

  submitInsufficientCredit(payload) {
    return this.http.post(`${environment.apiUrl}${UNIT_INSUFFICIENT_CREDIT_SUBMISSION}`, payload);
  }

  sendInsufficientCreditNoticeUpdate(payload) {
    return this.http.post(`${environment.apiUrl}${UNIT_INSUFFICIENT_CREDIT_NOTICE_UPDATE}`, payload);
  }

  cancelExcessCredits(payload) {
    return this.http.post(`${environment.apiUrl}${UNIT_CANCEL_EXCESS_CREDIT}`, payload);
  }

  checkForActiveCCM(): Observable<any> {
    return this.http.get(`${environment.apiUrl}${CHECK_FOR_ACTIVE_CCM}`);
  }

  getFuelEnergyDensity(fuelId: number): Observable<IApp.ILookup[]> {
    return this.http.get<IApp.ILookup[]>(`${ environment.apiUrl }${ CCR_FUEL_ENERGY_DENSITY_URI }/${ fuelId }`);
  }

  getCFRReferenceCarbonIntensity(year: number, fuelId: number): Observable<any> {
    return this.http.get(`${ environment.apiUrl }${ CCR_REFERENCE_CARBON_INTENSITY_FOR_FUEL }/${ year }/${ fuelId }`);
  }

  getAlphanumericIDofCI(payload: ICreditCreationReportGetAlphNumCI): Observable<IApp.ILookup[]> {
    return this.http.post<IApp.ILookup[]>(`${ environment.apiUrl }${ CCR_CARBON_INTENSITY_IDS_LOOKUP_URI }`, payload);
  }

  public showErrorMessage(message: string) {
    return this.modalService
      .open(
        ServiceMessageComponent,
        {
          messages: [
            {
              message: message,
            },
          ],
          message: message,
          type: SereviceMessageType.ERROR,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      )
      .afterClosed()
  }
}
