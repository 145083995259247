<p-dialog
  #dialog
  [className]="'eccc'"
  [(visible)]="visible"
  [modal]="true"
  [responsive]="true"
  [style]="{width: '800px'}"
  [minY]="100"
  [maximizable]="false"
  [autoZIndex]="false"
  [closable]="false"
  [header] = "'CREDIT_CREATION_REPORT.reportStep.addDetailsTitle' | translate"
  (onShow)="scrollToTop()">
  <form [formGroup]="currentStepObj.form">
    <formly-form [form]="currentStepObj.form" [model]="currentStepObj.model" [fields]="currentStepObj.fields"></formly-form>
  </form>
  <formly-form [form]="form" [model]="model" [fields]="needMoreInfoFields"></formly-form>

  <div class="row" *ngIf="currentStepObj.detailSectionDocumentUploadConfig && taskList.length">
    <div class="col-12">
      <div class="tools-selector">
        <ng-select
          wcag-label
          [placeholder]="'CREDIT_CREATION_REPORT.reportStep.downloadTemplate' | translate"
          [inputAttrs]="{'aria-label': 'COMMON.actionsLabel.tools' | translate}"
          [items]="taskList"
          bindLabel="name"
          bindValue="id"
          class="ng-dropdown-left"
          [(ngModel)]="downloadSelected"
          [appendTo]="body"
          (change)="downloadTemplate($event)">
        </ng-select>
      </div>
    </div>
  </div>

  <div *ngIf="currentStepObj.detailSectionDocumentUploadConfig" class="form-group mt-4">
    <ng-container *ngIf = "currentStepObj.documentUploadLabelConfig">
      <div class="row mb-3 p-1">
        <div class="col-12">
          {{currentStepObj.documentUploadLabelConfig.label | translate}}
          <fa-icon
          *ngIf="currentStepObj.documentUploadLabelConfig.tooltip"
          pTooltip="{{currentStepObj.documentUploadLabelConfig.tooltip | translate}}"
          tooltipPosition="top"
          [escape]="false"
          [icon]="['fas', 'info-circle']">
        </fa-icon>
        </div>
      </div>
    </ng-container>
    <app-documents
      [documentUploadConfig]="currentStepObj.detailSectionDocumentUploadConfig"
      (documentResult)="documentUploadHandler($event)"
      [disabled]="documentUploadDisabled"
      [showPlaceholders]="true">
    </app-documents>
  </div>

  <div class="d-flex justify-content-end mt-2">
    <button type="button" disableOnUpload class="btn btn-secondary mr-2" (click)="close()">{{'CREDIT_CREATION_REPORT.reportStep.close' | translate}}</button>
    <button type="button"
            disableOnUpload
            class="btn btn-primary"
            *ngIf="!currentStepObj.readOnly"
            (click)="save()">
      {{'CREDIT_CREATION_REPORT.reportStep.save' | translate}}
    </button>
  </div>
</p-dialog>
